import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import { I18nextProvider } from 'react-i18next';
import { isIE } from 'react-device-detect';
import { IAppState } from '../reducers';
import Wizard from './wizard/Wizard';
import DownloadReport from './DownloadReport';
import OAuthCallback from './OAuthCallback';
import RedirectToLogin from './RedirectToLogin';
import RedirectToIPBasedLogin from './RedirectToIPBasedLogin';
import { IENotAvailable } from './IENotAvailable';
import { ErrorBoundary } from './ErrorBoundary';
import { ScrollToTop } from './ScrollToTop';
import { i18n } from '../translations/i18n';
import { Access } from '../actions/app';

import './App.css';

interface IOwnProps {
  history: History;
}

interface IStateProps {
  locale: string;
  token?: string;
  access?: Access;
}

class Component extends React.Component<IOwnProps & IStateProps, {}> {
  public componentDidUpdate(prevProps: IOwnProps & IStateProps) {
    if (prevProps.locale !== this.props.locale) {
      i18n.changeLanguage(this.props.locale);
    }
  }

  public render() {
    const loggedIn: boolean = Boolean(this.props.token);
    const { locale, access } = this.props;

    return (
      <div className="app">
        <I18nextProvider
          defaultNS="translations"
          initialLanguage={locale}
          i18n={i18n}
        >
          <ErrorBoundary>
            <ConnectedRouter history={this.props.history}>
              <ScrollToTop>
                <div>
                  {isIE ? (
                    <IENotAvailable />
                  ) : (
                    <Switch>
                      <Route
                        path="/ip-login/:username"
                        component={RedirectToIPBasedLogin}
                      />
                      <Route
                        path="/callback"
                        render={() => <OAuthCallback />}
                      />
                      <Route
                        render={() =>
                          loggedIn ? <DownloadReport /> : <RedirectToLogin />
                        }
                        path="/download-report"
                      />
                      <Route
                        render={() =>
                          loggedIn ? (
                            <Wizard access={access} />
                          ) : (
                            <RedirectToLogin />
                          )
                        }
                        path="/"
                      />
                    </Switch>
                  )}
                </div>
              </ScrollToTop>
            </ConnectedRouter>
          </ErrorBoundary>
        </I18nextProvider>
      </div>
    );
  }
}

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    locale: state.locale,
    token: state.token,
    access: state.access,
  };
};

export const App = connect(mapStateToProps)(Component);
