import * as React from 'react';
import { v4 as uuid } from 'uuid';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { IAppState } from '../reducers';

interface IStateProps {
  pathname?: string;
}

class RedirectToLogin extends React.Component<IStateProps, {}> {
  public componentDidMount() {
    const csrfToken = uuid();
    localStorage.setItem('previousPathname', this.props.pathname || '/');
    localStorage.setItem('csrfToken', csrfToken);
    window.location.replace(
      process.env.REACT_APP_LOGIN_URL + `&state=${csrfToken}` || ''
    );
  }

  public render() {
    return null;
  }
}

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    pathname: state.router && state.router.location.pathname,
  };
};

export default compose(connect(mapStateToProps))(RedirectToLogin);
