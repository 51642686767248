import { Component, ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface IScrollToTopProps {
  children?: ReactNode;
}

class ScrollToTopImpl extends Component<
  IScrollToTopProps & RouteComponentProps<{}>,
  {}
> {
  public componentDidUpdate(
    prevProps: IScrollToTopProps & RouteComponentProps<{}>
  ) {
    const { location } = this.props;
    const { activeElement } = document;

    // Active element can be null if no input elements are selected. Only
    // HTMLElement objects have a blur method so check before calling blur.
    if (activeElement instanceof HTMLElement) {
      activeElement.blur();
    }

    if (location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  public render() {
    const { children } = this.props;
    return children;
  }
}

export const ScrollToTop = withRouter(ScrollToTopImpl);
