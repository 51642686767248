export function getIspName(id: string): string {
  let name = id;

  switch (id) {
    case 'rogers':
      name = 'Rogers (East)';
      break;
    case 'shaw':
      name = 'Rogers (West)';
      break;
  }

  return name;
}
