import { map, mapTo } from 'rxjs/operators';
import { ActionsObservable } from 'redux-observable';
import { push, replace } from 'connected-react-router';
import {
  ActionKeys,
  ActionTypes,
  IBulkLockValid,
  IBulkReserveValid,
  IBulkUseValid,
  IBulkReleaseValid,
  IGetStatusValidAction,
  IReserveValidAction,
  IUseValidAction,
  IReleaseValidAction,
  ILockValidAction,
  ICodeInvalidResponseAction,
} from '../actions/pins';

const statusToPathMap = {
  available: '/pin-address-entry',
  locked: '/pin-locked',
  reserved: '/pin-reserved',
  used: '/pin-used',
};

export const getStatusValidTransition = (
  action$: ActionsObservable<ActionTypes>
) =>
  action$.ofType<IGetStatusValidAction>(ActionKeys.GET_STATUS_VALID).pipe(
    map((action) => {
      if (action.response.status) {
        return push(
          statusToPathMap[
            action.response.status === 'available'
              ? 'available'
              : action.response.status === 'locked'
              ? 'locked'
              : action.response.status === 'reserved'
              ? 'reserved'
              : 'used'
          ]
        );
      }
    })
  );

export const reserveValidTransition = (
  action$: ActionsObservable<ActionTypes>
) =>
  action$
    .ofType<IReserveValidAction>(ActionKeys.RESERVE_VALID)
    .pipe(mapTo(replace('/pin-reserved')));

export const useValidTransition = (action$: ActionsObservable<ActionTypes>) =>
  action$
    .ofType<IUseValidAction>(ActionKeys.USE_VALID)
    .pipe(mapTo(replace('/pin-used')));

export const releaseValidTransition = (
  action$: ActionsObservable<ActionTypes>
) =>
  action$
    .ofType<IReleaseValidAction>(ActionKeys.RELEASE_VALID)
    .pipe(mapTo(replace('/pin-released')));

export const lockValidTransition = (action$: ActionsObservable<ActionTypes>) =>
  action$
    .ofType<ILockValidAction>(ActionKeys.LOCK_VALID)
    .pipe(mapTo(replace('/pin-locked')));

export const codeInvalidTransistion = (
  action$: ActionsObservable<ActionTypes>
) =>
  action$
    .ofType<ICodeInvalidResponseAction>(ActionKeys.CODE_INVALID_RESPONSE)
    .pipe(mapTo(push('/pin-error')));

export const bulkUpdateTransistion = (
  action$: ActionsObservable<ActionTypes>
) =>
  action$
    .ofType<
      IBulkLockValid | IBulkReserveValid | IBulkUseValid | IBulkReleaseValid
    >(
      ActionKeys.BULK_LOCK_VALID,
      ActionKeys.BULK_RESERVE_VALID,
      ActionKeys.BULK_USE_VALID,
      ActionKeys.BULK_RELEASE_VALID
    )
    .pipe(
      map(
        (
          action:
            | IBulkLockValid
            | IBulkReserveValid
            | IBulkUseValid
            | IBulkReleaseValid
        ) => {
          if (action.response.dryRun) {
            return push('/csv-confirm');
          }
          return replace('/csv-results');
        }
      )
    );
