import { TFunction } from 'i18next';
import moment from 'moment';
import { ReasonCodes } from '../actions/pins';
import { formatList } from './formatList';

export function getErrorMessageFromReasonCode(
  t: TFunction,
  locale: string,
  reasonCode: ReasonCodes,
  values: {
    badStatus?: string;
    expiresAt?: string;
    goodStatuses?: string[];
    prefix?: string | string[];
  }
): string {
  const map = {
    [ReasonCodes.MAXIMUM_REACHED]: '',
    [ReasonCodes.INVALID_EXPIRED]: 'errors.invalidExpiredOn',
    [ReasonCodes.INVALID_ISP]: 'errors.invalidIsp',
    [ReasonCodes.NO_ACCESS]: 'errors.noAccess',
    [ReasonCodes.INVALID_PIN_NOT_FOUND]: 'errors.invalidPinNotFound',
    [ReasonCodes.INVALID_PIN_PREFIX]: 'errors.invalidPinPrefix',
    [ReasonCodes.INVALID_PIN_TOO_LONG]: 'errors.invalidPinTooLong',
    [ReasonCodes.INVALID_PIN_TOO_SHORT]: 'errors.invalidPinTooShort',
    [ReasonCodes.INVALID_PRICE]: 'errors.invalidPrice', // Fallback error on the off chance a locked pin has no price
    [ReasonCodes.INVALID_STATUS]: 'errors.invalidStatus',
    [ReasonCodes.INVALID_STATUS_NO_APPLICATION]:
      'errors.invalidStatusNoApplication',
    [ReasonCodes.INVALID_VERIFICATION]: 'errors.invalidVerification',
    [ReasonCodes.LIMIT_REACHED]: 'errors.limitReached',
  };

  const transCode = map[reasonCode];

  moment.locale(locale);

  const date = values.expiresAt
    ? moment(values.expiresAt).format(t('errors.expiresAtDateFormat'))
    : undefined;

  const badStatus = values.badStatus
    ? t(`pinStatus.${values.badStatus}`)
    : undefined;

  const goodStatuses = values.goodStatuses
    ? values.goodStatuses.map((status) => t(`pinStatus.${status}`)).join(', ')
    : undefined;

  const prefixes = formatList(
    (Array.isArray(values.prefix)
      ? values.prefix
      : values.prefix !== undefined
      ? [values.prefix]
      : []
    ).map(
      (val) => `${t('list.quotationOpener')}${val}${t('list.quotationCloser')}`
    ),
    t('list.separator'),
    t('list.disjunction')
  );

  return t(transCode, {
    badStatus,
    date,
    goodStatuses,
    prefixes,
  });
}
