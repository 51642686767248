import * as React from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/en-ca';
import 'moment/locale/fr-ca';

interface IComment {
  locale: string;
  message: string;
  date: string;
}

const CommentImpl = (props: IComment & WithNamespaces) => {
  const { date, locale, t } = props;

  moment.locale(locale);
  const formattedDate = moment(date).format(t('comment.dateFormat'));
  const isoDate = new Date(date).toISOString();

  return (
    <div className="comment">
      <p>{props.message}</p>
      <time className="comment__date" dateTime={isoDate}>
        {formattedDate}
      </time>
    </div>
  );
};

export const Comment = withNamespaces()(CommentImpl);
