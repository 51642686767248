import * as React from 'react';
import { ajax } from 'rxjs/ajax';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { invalidateToken } from '../actions/app';
import { Container, Loader } from 'semantic-ui-react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { IAppState } from '../reducers';

interface IOwnProps {
  reportType?: string;
}

interface IStateProps {
  token?: string;
}

interface IDispatchProps {
  invalidateToken: typeof invalidateToken;
}

interface IReportState {
  csvContent: string;
  loading: boolean;
  hasAccess?: boolean;
}

interface IField {
  value: string;
  label?: string;
}

type Props = IOwnProps & IStateProps & IDispatchProps & WithNamespaces;

class Report extends React.Component<Props, IReportState> {
  constructor(props: Props) {
    super(props);
    this.state = { csvContent: '', loading: false, hasAccess: undefined };

    this.fetchData = this.fetchData.bind(this);
  }

  public componentDidMount() {
    this.setState({
      loading: true,
    });

    ajax
      .post(
        process.env.REACT_APP_API_BASE_URL + '/pin/get-report-access',
        {},
        {
          Authorization: `Bearer ${this.props.token}`,
          'Content-Type': 'application/json',
        }
      )
      .subscribe(
        (result) => {
          if (
            !result.response.hasComponentAccess &&
            this.props.reportType === 'full'
          ) {
            window.location.replace(process.env.REACT_APP_BASE_URL + `/`);
          }
          this.setState({
            hasAccess:
              result.response.hasComponentAccess ||
              this.props.reportType !== 'full',
            loading: true,
          });

          if (this.state.hasAccess) {
            this.fetchData();
          }
        },
        (error) => {
          if (error.status === 401) {
            return this.props.invalidateToken();
          } else {
            throw new Error(error);
          }
        }
      );
  }

  public render() {
    return (
      <Container>
        <Loader active={this.state.loading}>
          {this.props.t('general.loading')}
        </Loader>
      </Container>
    );
  }

  private fetchData() {
    const endpoint =
      this.props.reportType === 'full'
        ? process.env.REACT_APP_API_BASE_URL + '/pin/get-full-status-report '
        : process.env.REACT_APP_API_BASE_URL + '/pin/get-status-report ';
    ajax
      .post(
        endpoint,
        {},
        {
          Authorization: `Bearer ${this.props.token}`,
          'Content-Type': 'application/json',
        }
      )
      .subscribe(
        (result) => {
          const fields: IField[] =
            this.props.reportType === 'full'
              ? [
                  { label: 'PIN', value: 'pin' },
                  { label: 'PIN Status', value: 'status' },
                  { label: 'PIN Price', value: 'price' },
                  { label: 'Date PIN Created', value: 'created' },
                  { label: 'Date PIN Last Updated', value: 'lastUpdated' },
                  {
                    label: 'Date Application First Sent',
                    value: 'firstApplicationDate',
                  },
                  {
                    label: 'Date Expired',
                    value: 'expired',
                  },
                  { label: 'ISP', value: 'isp' },
                  { label: 'Province', value: 'province' },
                  { label: 'Computer Order', value: 'computerOrdered' },
                  {
                    label: 'Address Match',
                    value: 'mailingAddressIsServiceAddress',
                  },
                ]
              : [
                  { value: 'pin' },
                  { value: 'status' },
                  { value: 'price' },
                  { value: 'isp' },
                  { value: 'province' },
                  { value: 'lastUpdated' },
                ];

          const rows = [];
          rows.push(fields.map((f: IField) => f.label || f.value).join(','));
          for (const pinObj of result.response.pins) {
            const row = [];
            for (const field of fields) {
              row.push(pinObj[field.value]);
            }
            rows.push(row.join(','));
          }

          const csvContent = rows.join('\n');

          const data = new Blob([csvContent], { type: 'text/csv' });
          const csvURL = window.URL.createObjectURL(data);
          const tempLink = document.createElement('a');
          document.body.appendChild(tempLink);

          const date = new Date();
          tempLink.href = csvURL;
          tempLink.setAttribute(
            'download',
            `report-${date.getFullYear()}-${
              date.getMonth() + 1
            }-${date.getDate()}.csv`
          );
          tempLink.click();

          this.setState({ loading: false });
        },
        (error) => {
          if (error.status === 401) {
            return this.props.invalidateToken();
          } else {
            throw new Error(error);
          }
        }
      );
  }
}

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    token: state.token,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      invalidateToken,
      push,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(Report));
