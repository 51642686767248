import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Trans, withNamespaces, WithNamespaces } from 'react-i18next';
import { lock, release, updatePrice, Price } from '../../actions/pins';
import { IAppState } from '../../reducers';
import PinBubble from '../PinBubble';
import ButtonWithDesc from '../ButtonWithDesc';
import CommentSection from '../comments/CommentSection';
import { getStatusLogs } from '../../actions/statusLogs';

interface IPinStatusUsedScreenProps {
  release: any;
  lock: any;
  updatePrice: any;
  getStatusLogs: any;
}

interface IStateProps {
  normalizedPin?: string;
  loadingLock: boolean;
  loadingRelease: boolean;
  loadingPrice: boolean;
  price?: Price;
  postalCode?: string;
}

const PinStatusUsedScreen = (
  props: IPinStatusUsedScreenProps & IStateProps & WithNamespaces
) => {
  const { t, postalCode, price } = props;

  const priceTranslation = {
    price: price === '20' ? t('prices.twentyDollar') : t('prices.tenDollar'),
  };

  const successMessage = (
    <Trans i18nKey="pinMessages.pinHasBeenUsedWithPrice">
      PIN has been marked as <strong>Used</strong> for
      <strong>{priceTranslation}</strong> internet and installation is complete.
    </Trans>
  );

  const postalCodeInfo =
    postalCode !== undefined
      ? {
          postalCode,
          title: t('general.postalCode'),
        }
      : undefined;

  return (
    <div>
      <PinBubble
        valid={true}
        normalizedPin={props.normalizedPin}
        successMessage={successMessage}
        postalCodeInfo={postalCodeInfo}
      />

      <ButtonWithDesc
        buttonText={t('buttons.release')}
        loading={props.loadingRelease}
        onClick={() => props.release(props.normalizedPin)}
      >
        <p>{t('pinMessages.cancelInstallation')}</p>
      </ButtonWithDesc>
      <ButtonWithDesc
        buttonText={t('buttons.lock')}
        loading={props.loadingLock}
        onClick={() => props.lock(props.normalizedPin)}
      >
        <p>{t('pinMessages.ifDisconnectedDueToNonPayment')}</p>
      </ButtonWithDesc>
      <ButtonWithDesc
        buttonText={t('buttons.changePrice', {
          price:
            price === '20' ? t('prices.tenDollar') : t('prices.twentyDollar'),
        })}
        loading={props.loadingPrice}
        onClick={async () => {
          await new Promise((resolve, reject) => {
            props.updatePrice(
              props.normalizedPin,
              price === '20' ? '10' : '20',
              {
                reject,
                resolve,
              }
            );
          });
          props.getStatusLogs(props.normalizedPin);
        }}
      >
        <Trans i18nKey="pinMessages.currentPrice">
          This PIN is currently marked to a maximum of{' '}
          <strong>{priceTranslation}</strong> plan.
        </Trans>
      </ButtonWithDesc>
      <CommentSection />
    </div>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    loadingLock: state.loadingLock,
    loadingPrice: state.loadingPrice,
    loadingRelease: state.loadingRelease,
    normalizedPin: state.normalizedPin,
    price: state.price,
    postalCode: state.postalCode,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      lock,
      release,
      updatePrice,
      getStatusLogs,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(PinStatusUsedScreen));
