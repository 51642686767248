import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Container, Form, Message, Button } from 'semantic-ui-react';
import { ajax } from 'rxjs/ajax';
import MaskedInput from 'react-text-mask';
import _ from 'lodash';
import moment from 'moment';
import { IAppState } from '../reducers';
import { invalidateToken } from '../actions/app';
import { Comment } from './comments/Comment';
import { getIspName } from './../util/getIspName';
import './PinTool.css';

interface IAddressProps {
  line1: string;
  line2?: string;
  city: string;
  regionId: string;
  postalCode: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
}

const Address: React.SFC<IAddressProps> = (props) => {
  return (
    <p>
      <span>
        <b>Line 1: </b>
        {props.line1}
      </span>
      <br />
      <span>
        <b>Line 2: </b>
        {props.line2 || '-'}
      </span>
      <br />
      <span>
        <b>City: </b>
        {props.city}
      </span>
      <br />
      <span>
        <b>Province: </b>
        {props.regionId}
      </span>
      <br />
      <span>
        <b>Postal Code: </b>
        {props.postalCode}
      </span>
    </p>
  );
};

interface IPinData {
  pin: any;
  application: any;
  logs: any[];
  normalizedPin?: string;
}

interface IPinDataReportProps {
  pinData: IPinData;
}

const PinDataReport: React.SFC<IPinDataReportProps> = (props) => {
  if (!props.pinData.application) {
    return (
      <div className="PinTool__report">
        <h1>No application found for {props.pinData.normalizedPin}</h1>
      </div>
    );
  }

  const {
    mailingAddress,
    serviceAddress,
    computerAddress,
  } = props.pinData.application;
  const { pin, application, logs } = props.pinData;
  moment.locale('en');

  return (
    <div className="PinTool__report">
      <h1>Pin Report for {props.pinData.pin.id}</h1>
      <h2>General Pin Info</h2>
      <p>
        <span>
          <b>Current Status: </b>
          {_.upperFirst(pin.status)}
        </span>
        <br />
        <span>
          <span>
            <b>Current Price: </b>
            {pin.price}
          </span>
          <br />
          <b>Current ISP: </b>
          {_.upperFirst(getIspName(pin.userId))}
        </span>
        <br />
        <span>
          <b>Generation: </b>
          {pin.importBatch}
        </span>
        <br />
      </p>
      <h2>Last Application</h2>
      <p>
        <span>
          <b>Date Created: </b>
          {moment(application.createdAt).format('MMMM D, YYYY h:mm A')}
        </span>
        <br />
        <span>
          <b>Email: </b>
          {_.upperFirst(application.email)}
        </span>
        <br />
        <span>
          <b>Phone: </b>
          {_.upperFirst(application.phone)}
        </span>
        <br />
        <span>
          <b>Language: </b>
          {application.languageId === 'en-CA' ? 'English' : 'French'}
        </span>
        <br />
        <span>
          <b>Provider: </b>
          {_.upperFirst(application.provider)}
        </span>
        <br />
      </p>

      <h2>Mailing Address</h2>
      <Address {...mailingAddress} />
      <h2>Service Address</h2>
      <Address {...serviceAddress} />
      {computerAddress && (
        <>
          <h2>Computer Address</h2>
          <Address {...computerAddress} />
        </>
      )}
      <h2>Status Logs</h2>
      {logs
        .filter(
          (log) =>
            // Do not display the logs for when the price is nulled.
            log.newStatus || log.newPrice || (log.newPrice && log.oldPrice)
        )
        .map((log) => (
          <div key={log.id} className="PinTool__log">
            {log.newStatus ? (
              <Comment
                locale="en"
                message={`${_.upperFirst(
                  getIspName(log.userId)
                )} moved the PIN from ${_.upperFirst(
                  log.oldStatus
                )} to ${_.upperFirst(log.newStatus)}.`}
                date={log.createdAt}
              />
            ) : log.oldPrice ? (
              <Comment
                locale="en"
                message={`${_.upperFirst(
                  getIspName(log.userId)
                )} changed the Price from ${log.oldPrice} to ${log.newPrice}.`}
                date={log.createdAt}
              />
            ) : (
              <Comment
                locale="en"
                message={`${_.upperFirst(
                  getIspName(log.userId)
                )} set the Price to ${log.newPrice}.`}
                date={log.createdAt}
              />
            )}
          </div>
        ))}
    </div>
  );
};

const mask = [/\w/, /\w/, ' ', /\w/, /\w/, ' ', /\w/, /\w/, ' ', /\w/, /\w/];

interface IFormValues {
  pin: string;
}

interface IStateProps {
  token?: string;
}

interface IDispatchProps {
  invalidateToken: typeof invalidateToken;
}

interface IOwnState {
  loading: boolean;
  pinData?: IPinData;
  error?: string;
}

type Props = IStateProps &
  IDispatchProps & {
    hasOwnUrl?: boolean;
  };

class PinToolImpl extends React.Component<Props, IOwnState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: undefined,
      loading: false,
      pinData: undefined,
    };

    this.fetchData = this.fetchData.bind(this);
  }

  public render() {
    return (
      <Container className="PinTool" text={true}>
        <h1>PIN History</h1>

        <Formik<IFormValues>
          initialValues={{
            pin: '',
          }}
          onSubmit={(values) => {
            this.fetchData(values.pin);
          }}
          validate={(values) => {
            const errors: { pin?: string } = {};
            const pinWithNoSpaces = values.pin.replace(/ /g, '');

            if (!values.pin) {
              errors.pin = 'Enter a PIN';
            } else if (
              pinWithNoSpaces.length !== 8 ||
              !['A', 'B'].includes(pinWithNoSpaces.slice(0, 1).toUpperCase())
            ) {
              errors.pin =
                'Invalid PIN format. Pins must begin with the letter A or letter B and be 8 characters long.';
            }

            return errors;
          }}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({ values, errors, handleChange, handleBlur, handleSubmit }) => {
            return (
              <Form
                size="large"
                error={true}
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <Form.Field>
                  <label htmlFor="pin">Enter a PIN</label>

                  <MaskedInput
                    mask={mask}
                    guide={false}
                    id="pin"
                    placeholder=""
                    type="text"
                    value={values.pin.toUpperCase()}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Message
                    error={true}
                    aria-live="polite"
                    content={errors.pin}
                  />
                </Form.Field>

                <Button loading={this.state.loading} type="submit">
                  Submit
                </Button>
              </Form>
            );
          }}
        </Formik>
        {this.state.error && <h2>{this.state.error}</h2>}
        {this.state.pinData && <PinDataReport pinData={this.state.pinData} />}
      </Container>
    );
  }

  private fetchData(pin: string) {
    const endpoint =
      process.env.REACT_APP_API_BASE_URL + '/pin/get-pin-report ';

    this.setState({
      error: undefined,
      loading: true,
      pinData: undefined,
    });

    ajax
      .post(
        endpoint,
        { pin },
        {
          Authorization: `Bearer ${this.props.token}`,
          'Content-Type': 'application/json',
        }
      )
      .subscribe(
        (result) => {
          const pinData = result.response;
          if (pinData.code === 'invalid-pin') {
            return this.setState({ loading: false, error: pinData.reason });
          }
          this.setState({ loading: false, pinData });
        },
        (error) => {
          if (error.status === 401) {
            return this.props.invalidateToken();
          } else if (error.status === 403) {
            return this.setState({
              loading: false,
              error: "You don't have access to reports",
            });
          } else {
            throw new Error(error);
          }
        }
      );
  }
}

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    token: state.token,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      invalidateToken,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PinToolImpl);
