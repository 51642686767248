import * as React from 'react';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { IAppState } from '../../reducers';
import { IBulkUpdateResponse } from '../../actions/pins';

interface IStateProps {
  bulkResponse?: IBulkUpdateResponse;
}

const Component = (props: IStateProps & WithNamespaces) => {
  return props.bulkResponse ? (
    <div>
      <p>{props.t('bulk.followingUpdated')}</p>
      <Form.TextArea
        rows={12}
        className="csv-confirm__text-area"
        value={props.bulkResponse.updatedPins.join('\n')}
      />
    </div>
  ) : null;
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    bulkResponse: state.bulkResponse,
  };
};

export const CSVUploadResults = connect(mapStateToProps)(
  withNamespaces()(Component)
);
