import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Button } from 'semantic-ui-react';
import { push } from 'connected-react-router';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import {
  bulkUse,
  bulkReserve,
  bulkLock,
  bulkRelease,
  IBulkFormValues,
  IBulkUpdateResponse,
} from '../../actions/pins';
import { IAppState } from '../../reducers';
import { getErrorMessageFromReasonCode } from '../../util/getErrorMessageFromReasonCode';

import './CSVUpload.css';

interface ICSVUploadConfirmProps {
  bulkLock: any;
  bulkRelease: any;
  bulkReserve: any;
  bulkUse: any;
  push: typeof push;
}

interface IStateProps {
  bulkFormValues?: IBulkFormValues;
  bulkResponse?: IBulkUpdateResponse;
  locale: string;
  loading: boolean;
}

const CSVConfirm = (
  props: ICSVUploadConfirmProps & IStateProps & WithNamespaces
) => {
  if (!props.bulkFormValues || !props.bulkResponse) {
    return null;
  }

  const { updateType } = props.bulkFormValues;
  const { validPins, invalidPins } = props.bulkResponse;
  const formattedValidationErrors = props.bulkResponse.invalidPins
    .map(({ normalizedPin, validationErrors }) => {
      return `${normalizedPin}    ${validationErrors
        .map(
          ({
            badStatus,
            expiresAt,
            goodStatuses,
            prefix,
            reason,
            reasonCode,
          }) =>
            reasonCode
              ? getErrorMessageFromReasonCode(
                  props.t,
                  props.locale,
                  reasonCode,
                  { badStatus, expiresAt, goodStatuses, prefix }
                )
              : reason // Make error work the same on v1 api
        )
        .join(' ')}`;
    })
    .join('\n');

  const onClick = () => {
    if (!props.bulkFormValues) {
      return null;
    }

    switch (updateType) {
      case 'use': {
        props.bulkUse(props.bulkFormValues.file.data, false);
        break;
      }
      case 'reserve': {
        props.bulkReserve(props.bulkFormValues.file.data, false);
        break;
      }
      case 'lock': {
        props.bulkLock(props.bulkFormValues.file.data, false);
        break;
      }
      case 'release': {
        props.bulkRelease(props.bulkFormValues.file.data, false);
        break;
      }
    }
  };

  return (
    <div>
      <p>
        <b>
          {props.t(`bulk.${updateType}Mark`, {
            count: validPins.length,
          })}
        </b>
      </p>

      {invalidPins.length === 0 ? null : (
        <p>
          <b>
            {props.t(`bulk.willNotBeUpdated`, {
              count: invalidPins.length,
            })}
          </b>
        </p>
      )}

      {validPins.length === 0 && (
        <p>
          <a
            href=""
            onClick={(e) => {
              props.push('/csv-upload');
              e.preventDefault();
            }}
          >
            {props.t('bulk.uploadDifferent')}
          </a>
        </p>
      )}

      {invalidPins.length === 0 ? null : (
        <Form.TextArea
          rows={12}
          className="csv-confirm__text-area"
          value={formattedValidationErrors}
        />
      )}

      <Button
        size="large"
        color="blue"
        disabled={validPins.length === 0}
        loading={props.loading}
        onClick={onClick}
      >
        {props.t('buttons.updateAndContinue')}
      </Button>
    </div>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    bulkFormValues: state.bulkFormValues,
    bulkResponse: state.bulkResponse,
    locale: state.locale,
    loading:
      state.loadingBulkLock ||
      state.loadingBulkReserve ||
      state.loadingBulkUse ||
      state.loadingBulkRelease,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      bulkLock,
      bulkRelease,
      bulkReserve,
      bulkUse,
      push,
    },
    dispatch
  );
};

export const CSVUploadConfirm = connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(CSVConfirm));
