import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getStatusLogs, IStatusLog } from '../../actions/statusLogs';
import { Divider, Loader } from 'semantic-ui-react';
import { IAppState } from '../../reducers';
import { Comment } from './Comment';
import { StatusLog } from './StatusLog';
import { PriceLog } from './PriceLog';
import _ from 'lodash';

interface IStatusLogListProps {
  getStatusLogs: any;
}

interface IStateProps {
  normalizedPin?: string;
  loading: boolean;
  locale: string;
  statusLogs: IStatusLog[];
}

class StatusLogList extends React.Component<
  IStatusLogListProps & IStateProps,
  {}
> {
  public componentDidMount() {
    this.props.getStatusLogs(this.props.normalizedPin);
  }

  public render() {
    const locale = this.props.locale === 'fr' ? 'fr-ca' : this.props.locale;
    const statusLogs = _.orderBy(this.props.statusLogs, 'date', 'desc').filter(
      (statusLog) =>
        // Do not display the logs for when the price is nulled.
        statusLog.newStatus ||
        statusLog.newPrice ||
        (statusLog.newPrice && statusLog.oldPrice)
    );
    return (
      <div>
        <Loader
          className="comment-list__loader"
          size="small"
          inline="centered"
          active={this.props.loading}
        />
        {statusLogs.length > 0 && <Divider />}
        {statusLogs.map((statusLog) => {
          return (
            <div key={statusLog.date}>
              {statusLog.message ? (
                <Comment
                  date={statusLog.date}
                  locale={locale}
                  message={statusLog.message}
                />
              ) : statusLog.newStatus ? (
                <StatusLog
                  date={statusLog.date}
                  locale={locale}
                  status={statusLog.newStatus}
                />
              ) : (
                <PriceLog
                  date={statusLog.date}
                  locale={locale}
                  price={statusLog.newPrice}
                />
              )}
              <Divider />
            </div>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    loading: state.loadingGetStatusLogs,
    locale: state.locale,
    normalizedPin: state.normalizedPin,
    statusLogs: state.statusLogs,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      getStatusLogs,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(StatusLogList);
