import * as queryString from 'query-string';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import {
  IReceiveToken,
  receiveToken,
  setAccess,
  ISetAccess,
  Access,
} from '../actions/app';

interface IAuthCallbackProps extends RouteComponentProps<{}> {
  receiveToken: (token: string) => IReceiveToken;
  setAccess: (access?: Access) => ISetAccess;
}

class OAuthCallback extends React.Component<IAuthCallbackProps, {}> {
  public componentDidMount() {
    const parsedHash = queryString.parse(window.location.hash);
    const token = parsedHash.access_token;
    const csrfToken = parsedHash.state;
    if (localStorage.getItem('csrfToken') === csrfToken) {
      const previousPathname = localStorage.getItem('previousPathname') || '/';
      localStorage.removeItem('csrfToken');
      localStorage.removeItem('previousPathname');
      this.props.receiveToken(token);
      this.props.setAccess();
      this.props.history.replace(previousPathname);
    } else {
      localStorage.removeItem('csrfToken');
      localStorage.removeItem('previousPathname');
      throw new Error('Unable to securely parse auth token.');
    }
  }

  public render() {
    return null;
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      receiveToken,
      setAccess,
    },
    dispatch
  );
};

export default withRouter(connect(null, mapDispatchToProps)(OAuthCallback));
