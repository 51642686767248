import * as React from 'react';
import { v4 as uuid } from 'uuid';
import * as queryString from 'query-string';
import { RouteComponentProps, withRouter } from 'react-router-dom';

class RedirectToIPBasedLogin extends React.Component<
  RouteComponentProps<{ username: string }>,
  {}
> {
  public componentDidMount() {
    const getVars = queryString.parse(this.props.location.search);
    if (getVars.pathname) {
      localStorage.setItem('previousPathname', getVars.pathname);
    }

    const csrfToken = uuid();
    localStorage.setItem('csrfToken', csrfToken);
    window.location.replace(
      process.env.REACT_APP_IP_LOGIN_URL +
        `&state=${csrfToken}&username=${this.props.match.params.username}`
    );
  }
  public render() {
    return null;
  }
}

export default withRouter(RedirectToIPBasedLogin);
