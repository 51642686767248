/* ISP Pin related Action Types and Action Creators */

export enum ActionKeys {
  GET_STATUS = 'GET_STATUS',
  GET_STATUS_VALID = 'GET_STATUS_VALID',
  GET_STATUS_FAIL = 'GET_STATUS_FAIL',
  RESERVE = 'RESERVE',
  RESERVE_VALID = 'RESERVE_VALID',
  RESERVE_FAIL = 'RESERVE_FAIL',
  RESERVE_INVALID = 'RESERVE_INVALID',
  UPDATE_PRICE = 'UPDATE_PRICE',
  UPDATE_PRICE_VALID = 'UPDATE_PRICE_VALID',
  UPDATE_PRICE_FAIL = 'UPDATE_PRICE_FAIL',
  USE = 'USE',
  USE_VALID = 'USE_VALID',
  USE_FAIL = 'USE_FAIL',
  LOCK = 'LOCK',
  LOCK_VALID = 'LOCK_VALID',
  LOCK_FAIL = 'LOCK_FAIL',
  RELEASE = 'RELEASE',
  RELEASE_VALID = 'RELEASE_VALID',
  RELEASE_FAIL = 'RELEASE_FAIL',
  CODE_INVALID_RESPONSE = 'CODE_INVALID_RESPONSE',
  BULK_USE = 'BULK_USE',
  BULK_USE_VALID = 'BULK_USE_VALID',
  BULK_USE_FAIL = 'BULK_USE_FAIL',
  BULK_RESERVE = 'BULK_RESERVE',
  BULK_RESERVE_VALID = 'BULK_RESERVE_VALID',
  BULK_RESERVE_FAIL = 'BULK_RESERVE_FAIL',
  BULK_LOCK = 'BULK_LOCK',
  BULK_LOCK_VALID = 'BULK_LOCK_VALID',
  BULK_LOCK_FAIL = 'BULK_LOCK_FAIL',
  BULK_RELEASE = 'BULK_RELEASE',
  BULK_RELEASE_VALID = 'BULK_RELEASE_VALID',
  BULK_RELEASE_FAIL = 'BULK_RELEASE_FAIL',
  SET_BULK_UPDATE_FORM = 'SET_BULK_UPDATE_FORM',
  GET_AFFILIATES_REPORT = 'GET_AFFILIATES_REPORT',
  GET_AFFILIATES_REPORT_FULFILLED = 'GET_AFFILIATES_REPORT_FULFILLED',
}

/* Various interfaces and types */

export enum ResponseCodes {
  VALID = 'valid',
  MAXIMUM_REACHED = 'maximum-reached',
  INVALID_PIN = 'invalid-pin',
  INVALID_ISP = 'invalid-isp',
  INVALID_EXPIRED = 'invalid-expired',
  INVALID_VERIFICATION = 'invalid-verification',
  INVALID_STATUS = 'invalid-status',
}

export enum ReasonCodes {
  MAXIMUM_REACHED = 'maximum-reached',
  INVALID_PIN_NOT_FOUND = 'invalid-pin-not-found',
  INVALID_PIN_PREFIX = 'invalid-pin-prefix',
  INVALID_PIN_TOO_LONG = 'invalid-pin-too-long',
  INVALID_PIN_TOO_SHORT = 'invalid-pin-too-short',
  INVALID_PRICE = 'invalid-price',
  INVALID_ISP = 'invalid-isp',
  INVALID_EXPIRED = 'invalid-expired',
  INVALID_VERIFICATION = 'invalid-verification',
  INVALID_STATUS = 'invalid-status',
  INVALID_STATUS_NO_APPLICATION = 'invalid-status-no-application',
  LIMIT_REACHED = 'limit-reached',
  NO_ACCESS = 'no-access',
}

export type Price = '10' | '20';

export interface IApplication {
  mailingAddress: {
    city: string;
    createdAt: string;
    firstName: string | null;
    id: string;
    lastName: string | null;
    line1: string;
    line2: string | null;
    postalCode: string;
    regionId: string;
    updatedAt: string;
  };
  serviceAddress: {
    city: string;
    createdAt: string;
    firstName: string | null;
    id: string;
    lastName: string | null;
    line1: string;
    line2: string | null;
    postalCode: string;
    regionId: string;
    updatedAt: string;
  };
}

export interface INoMutationAPIResponse {
  badStatus?: string;
  code: ResponseCodes;
  expiresAt?: string;
  goodStatuses?: string[];
  normalizedPin: string;
  price?: Price;
  postalCode?: string;
  prefix?: string | string[];
  reason: string;
  reasonCode: ReasonCodes;
  status?: string;
}

export interface IMutationAPIResponse {
  code: ResponseCodes;
  newStatus: string;
  normalizedPin: string;
  oldStatus: string;
  price?: Price;
  reason: string;
}

export interface IPriceMutationAPIResponse {
  code: ResponseCodes;
  normalizedPin: string;
  oldPrice: Price;
  newPrice: Price;
  reason: string;
}

export interface IBulkValidationError {
  badStatus?: string;
  code: string;
  expiresAt?: string;
  goodStatuses?: string[];
  prefix?: string | string[];
  reason: string;
  reasonCode?: ReasonCodes;
}

export interface IInvalidPin {
  normalizedPin: string;
  validationErrors: IBulkValidationError[];
}

export interface IBulkUpdateResponse {
  dryRun: boolean;
  updatedPins: string[];
  invalidPins: IInvalidPin[];
  validPins: string[];
}
export interface IGetAffiliatesReportResponse {
  pin?: string;
  application?: IApplication;
  code?: ResponseCodes;
  reason: string;
}

export interface IAPIError {
  message: string;
  status: number;
}

interface IMeta {
  reject: any;
  resolve: any;
}

/* Action Types */

export interface IGetStatusAction {
  type: ActionKeys.GET_STATUS;
  pin: string;
  meta?: IMeta;
}

export interface IGetStatusValidAction {
  type: ActionKeys.GET_STATUS_VALID;
  response: INoMutationAPIResponse;
}

export interface IGetStatusFailAction {
  type: ActionKeys.GET_STATUS_FAIL;
  error: IAPIError;
}

export interface IGetAffiliatesReportAction {
  type: ActionKeys.GET_AFFILIATES_REPORT;
  pin: string;
  meta?: IMeta;
}
export interface IGetAffiliatesReportFulfilledAction {
  type: ActionKeys.GET_AFFILIATES_REPORT_FULFILLED;
  response: IGetAffiliatesReportResponse;
}

export interface IReserveAction {
  type: ActionKeys.RESERVE;
  pin: string;
  postalCode: string;
  meta?: IMeta;
}

export interface IReserveValidAction {
  type: ActionKeys.RESERVE_VALID;
  response: IMutationAPIResponse;
}

export interface IReserveInvalidAction {
  type: ActionKeys.RESERVE_INVALID;
  response: INoMutationAPIResponse;
}

export interface IReserveFailAction {
  type: ActionKeys.RESERVE_FAIL;
  error: IAPIError;
}

export interface IUpdatePriceAction {
  type: ActionKeys.UPDATE_PRICE;
  pin: string;
  price: Price;
  meta?: IMeta;
}

export interface IUpdatePriceValidAction {
  type: ActionKeys.UPDATE_PRICE_VALID;
  response: IPriceMutationAPIResponse;
}

export interface IUpdatePriceFailAction {
  type: ActionKeys.UPDATE_PRICE_FAIL;
  error: IAPIError;
}

export interface IUseAction {
  type: ActionKeys.USE;
  pin: string;
  price: Price;
}

export interface IUseValidAction {
  type: ActionKeys.USE_VALID;
  response: IMutationAPIResponse;
}

export interface IUseFailAction {
  type: ActionKeys.USE_FAIL;
  error: IAPIError;
}

export interface IReleaseAction {
  type: ActionKeys.RELEASE;
  pin: string;
}

export interface IReleaseValidAction {
  type: ActionKeys.RELEASE_VALID;
  response: IMutationAPIResponse;
}

export interface IReleaseFailAction {
  type: ActionKeys.RELEASE_FAIL;
  error: IAPIError;
}

export interface ILockAction {
  type: ActionKeys.LOCK;
  pin: string;
}

export interface ILockValidAction {
  type: ActionKeys.LOCK_VALID;
  response: IMutationAPIResponse;
}

export interface ILockFailAction {
  type: ActionKeys.LOCK_FAIL;
  error: IAPIError;
}

export interface ICodeInvalidResponseAction {
  response: INoMutationAPIResponse;
  type: ActionKeys.CODE_INVALID_RESPONSE;
}

export interface IBulkUse {
  type: ActionKeys.BULK_USE;
  data: Array<{ pin: string }>;
  meta?: IMeta;
  dryRun?: boolean;
}

export interface IBulkUseValid {
  type: ActionKeys.BULK_USE_VALID;
  response: IBulkUpdateResponse;
}

export interface IBulkUseFail {
  type: ActionKeys.BULK_USE_FAIL;
  error: IAPIError;
}

export interface IBulkReserve {
  type: ActionKeys.BULK_RESERVE;
  data: Array<{ pin: string; postalCode: string }>;
  meta?: IMeta;
  dryRun?: boolean;
}

export interface IBulkReserveValid {
  type: ActionKeys.BULK_RESERVE_VALID;
  response: IBulkUpdateResponse;
}

export interface IBulkReserveFail {
  type: ActionKeys.BULK_RESERVE_FAIL;
  error: IAPIError;
}

export interface IBulkLock {
  type: ActionKeys.BULK_LOCK;
  data: Array<{ pin: string }>;
  meta?: IMeta;
  dryRun?: boolean;
}

export interface IBulkLockValid {
  type: ActionKeys.BULK_LOCK_VALID;
  response: IBulkUpdateResponse;
}

export interface IBulkLockFail {
  type: ActionKeys.BULK_LOCK_FAIL;
  error: IAPIError;
}

export interface IBulkRelease {
  type: ActionKeys.BULK_RELEASE;
  data: Array<{ pin: string }>;
  meta?: IMeta;
  dryRun?: boolean;
}

export interface IBulkReleaseValid {
  type: ActionKeys.BULK_RELEASE_VALID;
  response: IBulkUpdateResponse;
}

export interface IBulkReleaseFail {
  type: ActionKeys.BULK_RELEASE_FAIL;
  error: IAPIError;
}

type BulkData = Array<{ pin: string; postalCode: string }>;
export interface IBulkFormValues {
  file: {
    data: BulkData;
    name: string;
  };
  updateType: string;
}

export interface ISetBulkUpdateForm {
  type: ActionKeys.SET_BULK_UPDATE_FORM;
  values: IBulkFormValues;
}

export type ActionTypes =
  | IGetStatusAction
  | IGetStatusValidAction
  | IGetStatusFailAction
  | IReserveAction
  | IReserveValidAction
  | IReserveFailAction
  | IReserveInvalidAction
  | IUpdatePriceAction
  | IUpdatePriceValidAction
  | IUpdatePriceFailAction
  | IUseAction
  | IUseValidAction
  | IUseFailAction
  | IReleaseAction
  | IReleaseValidAction
  | IReleaseFailAction
  | ILockAction
  | ILockValidAction
  | ILockFailAction
  | ICodeInvalidResponseAction
  | IBulkUse
  | IBulkUseValid
  | IBulkUseFail
  | IBulkReserve
  | IBulkReserveValid
  | IBulkReserveFail
  | IBulkLock
  | IBulkLockValid
  | IBulkLockFail
  | IBulkRelease
  | IBulkReleaseValid
  | IBulkReleaseFail
  | ISetBulkUpdateForm
  | IGetAffiliatesReportAction
  | IGetAffiliatesReportFulfilledAction;

/* Action Creators */

export const getStatus = (pin: string, meta?: IMeta): IGetStatusAction => ({
  meta,
  pin,
  type: ActionKeys.GET_STATUS,
});

export const getStatusValid = (
  response: INoMutationAPIResponse
): IGetStatusValidAction => ({
  response,
  type: ActionKeys.GET_STATUS_VALID,
});

export const getStatusFail = (error: IAPIError): IGetStatusFailAction => ({
  error,
  type: ActionKeys.GET_STATUS_FAIL,
});

export const reserve = (
  pin: string,
  postalCode: string,
  meta?: IMeta
): IReserveAction => ({
  meta,
  pin,
  postalCode,
  type: ActionKeys.RESERVE,
});

export const reserveValid = (
  response: IMutationAPIResponse
): IReserveValidAction => ({
  response,
  type: ActionKeys.RESERVE_VALID,
});

export const reserveFail = (error: IAPIError): IReserveFailAction => ({
  error,
  type: ActionKeys.RESERVE_FAIL,
});

export const reserveInvalid = (
  response: INoMutationAPIResponse
): IReserveInvalidAction => ({
  response,
  type: ActionKeys.RESERVE_INVALID,
});

export const updatePrice = (
  pin: string,
  price: Price,
  meta?: IMeta
): IUpdatePriceAction => ({
  meta,
  pin,
  price,
  type: ActionKeys.UPDATE_PRICE,
});

export const updatePriceValid = (
  response: IPriceMutationAPIResponse
): IUpdatePriceValidAction => ({
  response,
  type: ActionKeys.UPDATE_PRICE_VALID,
});

export const updatePriceFail = (error: IAPIError): IUpdatePriceFailAction => ({
  error,
  type: ActionKeys.UPDATE_PRICE_FAIL,
});

export const use = (pin: string, price: Price): IUseAction => ({
  pin,
  price,
  type: ActionKeys.USE,
});

export const singleUseValid = (
  response: IMutationAPIResponse
): IUseValidAction => ({
  response,
  type: ActionKeys.USE_VALID,
});

export const singleUseFail = (error: IAPIError): IUseFailAction => ({
  error,
  type: ActionKeys.USE_FAIL,
});

export const release = (pin: string): IReleaseAction => ({
  pin,
  type: ActionKeys.RELEASE,
});

export const releaseValid = (
  response: IMutationAPIResponse
): IReleaseValidAction => ({
  response,
  type: ActionKeys.RELEASE_VALID,
});

export const releaseFail = (error: IAPIError): IReleaseFailAction => ({
  error,
  type: ActionKeys.RELEASE_FAIL,
});

export const lock = (pin: string): ILockAction => ({
  pin,
  type: ActionKeys.LOCK,
});

export const lockValid = (
  response: IMutationAPIResponse
): ILockValidAction => ({
  response,
  type: ActionKeys.LOCK_VALID,
});

export const lockFail = (error: IAPIError): ILockFailAction => ({
  error,
  type: ActionKeys.LOCK_FAIL,
});

export const bulkUse = (
  data: Array<{ pin: string }>,
  dryRun: boolean,
  meta?: IMeta
): IBulkUse => ({
  data,
  dryRun,
  meta,
  type: ActionKeys.BULK_USE,
});

export const bulkUseValid = (response: IBulkUpdateResponse): IBulkUseValid => ({
  response,
  type: ActionKeys.BULK_USE_VALID,
});

export const bulkUseFail = (error: IAPIError): IBulkUseFail => ({
  error,
  type: ActionKeys.BULK_USE_FAIL,
});

export const bulkReserve = (
  data: Array<{ pin: string; postalCode: string }>,
  dryRun: boolean,
  meta?: IMeta
): IBulkReserve => ({
  data,
  dryRun,
  meta,
  type: ActionKeys.BULK_RESERVE,
});

export const bulkReserveValid = (
  response: IBulkUpdateResponse
): IBulkReserveValid => ({
  response,
  type: ActionKeys.BULK_RESERVE_VALID,
});

export const bulkReserveFail = (error: IAPIError): IBulkReserveFail => ({
  error,
  type: ActionKeys.BULK_RESERVE_FAIL,
});

export const bulkLock = (
  data: Array<{ pin: string }>,
  dryRun: boolean,
  meta?: IMeta
): IBulkLock => ({
  data,
  dryRun,
  meta,
  type: ActionKeys.BULK_LOCK,
});

export const bulkLockValid = (
  response: IBulkUpdateResponse
): IBulkLockValid => ({
  response,
  type: ActionKeys.BULK_LOCK_VALID,
});

export const bulkLockFail = (error: IAPIError): IBulkLockFail => ({
  error,
  type: ActionKeys.BULK_LOCK_FAIL,
});

export const bulkRelease = (
  data: Array<{ pin: string }>,
  dryRun: boolean,
  meta?: IMeta
): IBulkRelease => ({
  data,
  dryRun,
  meta,
  type: ActionKeys.BULK_RELEASE,
});

export const bulkReleaseValid = (
  response: IBulkUpdateResponse
): IBulkReleaseValid => ({
  response,
  type: ActionKeys.BULK_RELEASE_VALID,
});

export const bulkReleaseFail = (error: IAPIError): IBulkReleaseFail => ({
  error,
  type: ActionKeys.BULK_RELEASE_FAIL,
});

export const setBulkUpdateForm = (
  values: IBulkFormValues
): ISetBulkUpdateForm => {
  return {
    type: ActionKeys.SET_BULK_UPDATE_FORM,
    values,
  };
};

export const codeInvalidResponse = (
  response: INoMutationAPIResponse
): ICodeInvalidResponseAction => ({
  response,
  type: ActionKeys.CODE_INVALID_RESPONSE,
});

export const getAffiliatesReport = (
  pin: string,
  meta: IMeta
): IGetAffiliatesReportAction => ({
  meta,
  pin,
  type: ActionKeys.GET_AFFILIATES_REPORT,
});

export const getAffiliatesReportFulfilled = (
  response: IGetAffiliatesReportResponse
): IGetAffiliatesReportFulfilledAction => ({
  response,
  type: ActionKeys.GET_AFFILIATES_REPORT_FULFILLED,
});
