import * as React from 'react';
import { Divider } from 'semantic-ui-react';
import AddCommentForm from './AddCommentForm';
import CommentList from './CommentList';

const CommentSection = () => {
  return (
    <div>
      <Divider />
      <AddCommentForm />
      <CommentList />
    </div>
  );
};

export default CommentSection;
