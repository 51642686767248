import { RouterState } from 'connected-react-router';

import {
  Access,
  ActionKeys as AppActionKeys,
  ActionTypes as AppActionTypes,
} from '../actions/app';

import {
  ActionKeys as PinActionKeys,
  ActionTypes as PinActionTypes,
  Price,
  ReasonCodes,
  IBulkUpdateResponse,
  IBulkFormValues,
  IGetAffiliatesReportResponse,
} from '../actions/pins';

import {
  ActionKeys as StatusLogActionKeys,
  ActionTypes as StatusLogActionTypes,
  IStatusLog,
} from '../actions/statusLogs';

export interface IAppState {
  access?: Access;
  postalCode?: string;
  bulkResponse?: IBulkUpdateResponse;
  bulkFormValues?: IBulkFormValues;
  invalidAddressReasonCode?: ReasonCodes;
  invalidPinBadStatus?: string;
  invalidPinExpiresAt?: string;
  invalidPinGoodStatuses?: string[];
  invalidPinPrefix?: string | string[];
  invalidPinReasonCode?: ReasonCodes;
  justReserved?: boolean;
  latestPinStatus?: string;
  maximumReached?: boolean;
  normalizedPin?: string;
  price?: Price;
  statusLogs: IStatusLog[];
  token?: string;
  loadingGetStatus: boolean;
  loadingLock: boolean;
  loadingPrice: boolean;
  loadingRelease: boolean;
  loadingReserve: boolean;
  loadingUse: boolean;
  loadingGetStatusLogs: boolean;
  loadingAddStatusLog: boolean;
  loadingBulkUse: boolean;
  loadingBulkReserve: boolean;
  loadingBulkLock: boolean;
  loadingBulkRelease: boolean;
  locale: string;
  router?: RouterState;
  affiliatesReport?: IGetAffiliatesReportResponse;
}

const defaultState: IAppState = {
  access: undefined,
  bulkFormValues: undefined,
  bulkResponse: undefined,
  invalidAddressReasonCode: undefined,
  invalidPinBadStatus: undefined,
  invalidPinExpiresAt: undefined,
  invalidPinGoodStatuses: undefined,
  invalidPinPrefix: undefined,
  invalidPinReasonCode: undefined,
  justReserved: undefined,
  latestPinStatus: undefined,
  loadingAddStatusLog: false,
  loadingBulkLock: false,
  loadingBulkRelease: false,
  loadingBulkReserve: false,
  loadingBulkUse: false,
  loadingGetStatus: false,
  loadingGetStatusLogs: false,
  loadingLock: false,
  loadingPrice: false,
  loadingRelease: false,
  loadingReserve: false,
  loadingUse: false,
  locale: 'en',
  price: undefined,
  maximumReached: false,
  normalizedPin: undefined,
  statusLogs: [],
  token: undefined,
  affiliatesReport: undefined,
};

type ActionTypes = AppActionTypes | PinActionTypes | StatusLogActionTypes;

export const rootReducer = (
  state: IAppState = defaultState,
  action: ActionTypes
): IAppState => {
  switch (action.type) {
    case AppActionKeys.RESET_APP: {
      return {
        ...defaultState,
        access: state.access, // Keep the access when resetting the app
        locale: state.locale, // Keep the locale when resetting the app
        token: state.token, // Keep the token when resetting the app
      };
    }

    case AppActionKeys.RECEIVE_TOKEN: {
      return {
        ...state,
        token: action.token,
      };
    }

    case AppActionKeys.INVALIDATE_TOKEN: {
      return {
        ...state,
        access: undefined,
        token: undefined,
      };
    }

    case AppActionKeys.SET_LOCALE: {
      return {
        ...state,
        locale: action.locale,
      };
    }

    case AppActionKeys.SET_ACCESS: {
      return {
        ...state,
        access: action.access,
      };
    }

    case PinActionKeys.GET_STATUS: {
      return {
        ...state,
        loadingGetStatus: true,
      };
    }

    case PinActionKeys.GET_STATUS_VALID: {
      return {
        ...state,
        latestPinStatus: action.response.status,
        loadingGetStatus: false,
        maximumReached: action.response.code === 'maximum-reached',
        normalizedPin: action.response.normalizedPin,
        price: action.response.price,
        postalCode: action.response.postalCode,
      };
    }

    case PinActionKeys.GET_STATUS_FAIL: {
      return {
        ...state,
        loadingGetStatus: false,
      };
    }

    case PinActionKeys.RESERVE: {
      return {
        ...state,
        loadingReserve: true,
        postalCode: action.postalCode,
      };
    }

    case PinActionKeys.RESERVE_VALID: {
      return {
        ...state,
        justReserved: true,
        latestPinStatus: action.response.newStatus,
        loadingReserve: false,
        maximumReached: action.response.code === 'maximum-reached',
        normalizedPin: action.response.normalizedPin,
      };
    }

    case PinActionKeys.RESERVE_FAIL: {
      return {
        ...state,
        loadingReserve: false,
      };
    }

    case PinActionKeys.RESERVE_INVALID: {
      return {
        ...state,
        invalidAddressReasonCode: action.response.reasonCode,
      };
    }

    case PinActionKeys.UPDATE_PRICE: {
      return {
        ...state,
        loadingPrice: true,
      };
    }

    case PinActionKeys.UPDATE_PRICE_VALID: {
      return {
        ...state,
        price: action.response.newPrice,
        loadingPrice: false,
      };
    }

    case PinActionKeys.UPDATE_PRICE_FAIL: {
      return {
        ...state,
        loadingPrice: false,
      };
    }

    case PinActionKeys.USE: {
      return {
        ...state,
        loadingUse: true,
      };
    }

    case PinActionKeys.USE_VALID: {
      return {
        ...state,
        latestPinStatus: action.response.newStatus,
        loadingUse: false,
        maximumReached: action.response.code === 'maximum-reached',
        normalizedPin: action.response.normalizedPin,
        price: action.response.price,
      };
    }

    case PinActionKeys.USE_FAIL: {
      return {
        ...state,
        loadingUse: false,
      };
    }

    case PinActionKeys.RELEASE: {
      return {
        ...state,
        loadingRelease: true,
      };
    }

    case PinActionKeys.RELEASE_VALID: {
      return {
        ...state,
        latestPinStatus: action.response.newStatus,
        loadingRelease: false,
        normalizedPin: action.response.normalizedPin,
      };
    }

    case PinActionKeys.RELEASE_FAIL: {
      return {
        ...state,
        loadingRelease: false,
      };
    }

    case PinActionKeys.LOCK: {
      return {
        ...state,
        loadingLock: true,
      };
    }

    case PinActionKeys.LOCK_VALID: {
      return {
        ...state,
        latestPinStatus: action.response.newStatus,
        loadingLock: false,
        normalizedPin: action.response.normalizedPin,
      };
    }

    case PinActionKeys.LOCK_FAIL: {
      return {
        ...state,
        loadingLock: false,
      };
    }

    case StatusLogActionKeys.ADD_STATUS_LOG: {
      return {
        ...state,
        loadingAddStatusLog: true,
      };
    }

    case StatusLogActionKeys.ADD_STATUS_LOG_FULFILLED: {
      return {
        ...state,
        loadingAddStatusLog: false,
      };
    }

    case StatusLogActionKeys.ADD_STATUS_LOG_FAIL: {
      return {
        ...state,
        loadingAddStatusLog: false,
      };
    }

    case StatusLogActionKeys.GET_STATUS_LOGS: {
      return {
        ...state,
        loadingGetStatusLogs: true,
      };
    }

    case StatusLogActionKeys.GET_STATUS_LOGS_FULFILLED: {
      return {
        ...state,
        loadingGetStatusLogs: false,
        statusLogs: action.response.logs,
      };
    }

    case StatusLogActionKeys.GET_STATUS_LOGS_FAIL: {
      return {
        ...state,
        loadingGetStatusLogs: false,
      };
    }

    case PinActionKeys.CODE_INVALID_RESPONSE: {
      return {
        ...state,
        invalidPinBadStatus: action.response.badStatus,
        invalidPinExpiresAt: action.response.expiresAt,
        invalidPinGoodStatuses: action.response.goodStatuses,
        invalidPinPrefix: action.response.prefix,
        invalidPinReasonCode: action.response.reasonCode,
        normalizedPin: action.response.normalizedPin,
      };
    }

    case PinActionKeys.BULK_USE: {
      return {
        ...state,
        loadingBulkUse: true,
      };
    }

    case PinActionKeys.BULK_USE_VALID: {
      return {
        ...state,
        bulkResponse: action.response,
        loadingBulkUse: false,
      };
    }

    case PinActionKeys.BULK_USE_FAIL: {
      return {
        ...state,
        loadingBulkUse: false,
      };
    }

    case PinActionKeys.BULK_RESERVE: {
      return {
        ...state,
        loadingBulkReserve: true,
      };
    }

    case PinActionKeys.BULK_RESERVE_VALID: {
      return {
        ...state,
        bulkResponse: action.response,
        loadingBulkReserve: false,
      };
    }

    case PinActionKeys.BULK_RESERVE_FAIL: {
      return {
        ...state,
        loadingBulkReserve: false,
      };
    }

    case PinActionKeys.BULK_LOCK: {
      return {
        ...state,
        loadingBulkLock: true,
      };
    }

    case PinActionKeys.BULK_LOCK_VALID: {
      return {
        ...state,
        bulkResponse: action.response,
        loadingBulkLock: false,
      };
    }

    case PinActionKeys.BULK_LOCK_FAIL: {
      return {
        ...state,
        loadingBulkLock: false,
      };
    }

    case PinActionKeys.BULK_RELEASE: {
      return {
        ...state,
        loadingBulkRelease: true,
      };
    }

    case PinActionKeys.BULK_RELEASE_VALID: {
      return {
        ...state,
        bulkResponse: action.response,
        loadingBulkRelease: false,
      };
    }

    case PinActionKeys.BULK_RELEASE_FAIL: {
      return {
        ...state,
        loadingBulkRelease: false,
      };
    }

    case PinActionKeys.SET_BULK_UPDATE_FORM: {
      return {
        ...state,
        bulkFormValues: action.values,
      };
    }

    case PinActionKeys.GET_AFFILIATES_REPORT_FULFILLED: {
      return {
        ...state,
        affiliatesReport: action.response,
      };
    }

    default:
      return state;
  }
};
