import { Container, Grid, Segment } from 'semantic-ui-react';

export function IENotAvailable() {
  return (
    <Container>
      <Grid centered={true} stackable={true}>
        <Grid.Column lang="en" textAlign="center" computer={7} tablet={8}>
          <Segment basic={true}>
            <p>
              <span>
                <b>Internet Explorer</b> is not supported.
              </span>
              <br />
              <span>Please use</span>
            </p>
            <p>Chrome/Edge/Firefox/Safari/Opera</p>
          </Segment>
        </Grid.Column>
        <Grid.Column lang="fr" textAlign="center" computer={7} tablet={8}>
          <Segment basic={true}>
            <p>
              <span>
                N’est pas compatible avec <b>Internet Explorer</b>.
              </span>
              <br />
              <span>Veuillez utiliser</span>
            </p>
            <p>Chrome/Edge/Firefox/Safari/Opera</p>
          </Segment>
        </Grid.Column>
      </Grid>
    </Container>
  );
}
