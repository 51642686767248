import { FormikProps, withFormik } from 'formik';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Button, TextArea, Label } from 'semantic-ui-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { IAppState } from '../../reducers';

import { addStatusLog, getStatusLogs } from '../../actions/statusLogs';

interface IFormValues {
  message: string;
}

interface IAddCommentFormProps {
  addStatusLog: any;
  getStatusLogs: any;
}

interface IStateProps {
  normalizedPin?: string;
}

type Props = RouteComponentProps<{}> &
  IAddCommentFormProps &
  IStateProps &
  WithNamespaces;

const MAX_LENGTH = 255;

const AddCommentForm = (props: FormikProps<IFormValues> & Props) => {
  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    t,
  } = props;

  const isTooLong = values.message.length > MAX_LENGTH;

  return (
    <div>
      <Form size="large" onSubmit={handleSubmit}>
        <Form.Field
          value={values.message}
          control={TextArea}
          id="message"
          onChange={handleChange}
          onBlur={handleBlur}
          className={
            errors.message && touched.message
              ? 'text-input error'
              : 'text-input'
          }
        />
        {isTooLong && (
          <div className="input-feedback ui message">
            {t('comment.tooLong')}
          </div>
        )}
        <Button
          as="div"
          fluid={true}
          disabled={values.message.length === 0 || isTooLong}
          labelPosition="right"
        >
          <Button
            size="large"
            color="blue"
            fluid={true}
            type="submit"
            loading={isSubmitting}
          >
            {t('buttons.addComment')}
          </Button>
          <Label basic size="large">
            {MAX_LENGTH - values.message.length}
          </Label>
        </Button>
      </Form>
    </div>
  );
};

const EnhancedForm = withFormik<Props, IFormValues>({
  displayName: 'AddCommentForm',
  handleSubmit: async (values, { props, setSubmitting, resetForm }) => {
    try {
      await new Promise((resolve, reject) => {
        props.addStatusLog(props.normalizedPin, values.message, {
          reject,
          resolve,
        });
      });
      setSubmitting(false);
      resetForm();
      props.getStatusLogs(props.normalizedPin);
    } catch (err) {
      setSubmitting(false);
      throw err;
    }
  },
  mapPropsToValues: () => ({ message: '' }),
})(AddCommentForm);

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    normalizedPin: state.normalizedPin,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      addStatusLog,
      getStatusLogs,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withNamespaces()(EnhancedForm)));
