const en = {
  bulk: {
    explanation: {
      paragraph1:
        'Bulk status updates of PINs can be performed by uploading a csv file in the format: <1> PIN, Postal Code, Price;</1>',
      paragraph2:
        '<0>Postal codes</0> are required for marking PINs as <3>Reserved</3>.',
      paragraph3:
        '<0>Prices</0> (20 or 10) are required for marking PINs as <3>Used</3>.',
      paragraph4: 'For example: <1>AB BB BB B2, M2R 1B1, 20</1>',
    },
    followingUpdated: 'The following PINs have been updated.',
    lockMark: '{{count}} PIN code(s) will be updated and marked as locked.',
    markLocked: 'Mark all PIN codes as <1>Locked.</1>',
    markReleased: 'Mark all PIN codes as <1>Released</1> and available again.',
    markReserved: 'Mark all PIN codes as <1>Reserved.</1>',
    markUsed: 'Mark all PIN codes as <1>Used.</1>',
    releaseMark:
      '{{count}} PIN code(s) will be updated and marked as available.',
    reserveMark:
      '{{count}} PIN code(s) will be updated and marked as reserved.',
    uploadDifferent: 'Upload a different csv file',
    useMark: '{{count}} PIN code(s) will be updated and marked as used.',
    willNotBeUpdated: '{{count}} PIN code(s) will not be updated.',
  },
  buttons: {
    addComment: 'Add Comment',
    addCsv: 'Add CSV file to Upload',
    changePrice: 'Change to {{price}}',
    completeInstallation10: 'Mark PIN as Used for $10/month',
    completeInstallation20: 'Mark PIN as Used for $20/month',
    lock: 'Lock',
    lookUpPinCode: 'Look Up PIN Code',
    release: 'Release',
    signOut: 'Sign Out',
    unlock: 'Unlock',
    updateAndContinue: 'Update and Continue',
    uploadFile: 'Upload File',
    validate: 'Validate',
    submit: 'Submit',
  },
  comment: {
    dateFormat: 'MMMM D, YYYY h:mm A',
    tooLong: 'Comment is too long.',
  },
  errors: {
    expiresAtDateFormat: 'MMMM D, YYYY',
    invalidExpiredOn: 'PIN code expired on {{date}}.',
    invalidIsp: 'PIN code already in use by another ISP.',
    invalidPinNotFound: 'PIN code does not exist.',
    invalidPinPrefix: 'PIN code should start with {{prefixes}}.',
    invalidPinTooLong: 'PIN code is too long. PIN codes are 8 characters.',
    invalidPinTooShort: 'PIN code is too short. PIN codes are 8 characters.',
    invalidPrice: 'The provided price is not valid.',
    invalidStatus:
      'PIN code status is currently “{{badStatus}}”, not “{{goodStatuses}}”.',
    invalidStatusNoApplication:
      'PIN code has not yet been validated. Please direct applicant to the Portal.',
    invalidVerification:
      'Postal code does not match PIN code. Please direct applicant to correct their service address in Portal.',
    limitReached:
      'This PIN code has already been used to order a free computer.',
    unexpectedError: 'An unexpected error has occurred.',
    noAccess: 'No Access',
  },
  formValidation: {
    pinRequired: 'PIN code is required.',
    postalCodeInvalid: 'Postal Code is not valid.',
    postalCodeRequired: 'Postal Code is required.',
  },
  general: {
    connectingFamilies: 'Connecting Families',
    disclaimer:
      'This is the staging site. Please use the <1>production</1> site for customers.',
    loading: 'Loading',
    no: 'No',
    postalCode: 'Postal Code',
    yes: 'Yes',
  },
  labels: {
    enterAddress: `Enter applicant's postal code to validate PIN code`,
    enterPinCode: 'Enter PIN code used by applicant',
  },
  links: {
    connectingFamiliesAdmin: 'https://admin.cf-fb.org',
  },
  list: {
    quotationOpener: '“',
    quotationCloser: '”',
    separator: ', ',
    disjunction: ' or ',
  },
  pinInvalidReasons: {},
  pinMessages: {
    alreadyReserved: 'PIN code has already been reserved.',
    cancelInstallation:
      'Cancel installation. Releasing a PIN code will change the status back to available.',
    confirmComplete:
      '<0>Before marking the PIN as used and installation complete</0>, has the customer received all of the required hardware and begun receiving their $10 Internet?',
    confirmInstallation:
      '<0>Please confirm:</0> Only mark the status of this PIN as <2>Used</2> after the customer’s <4>{{price}}</4> internet has been activated and they have been put into billing.',
    currentPrice:
      'This PIN is currently marked to a maximum of <2>{{price}}</2> plan.',
    ifDisconnectedDueToNonPayment:
      'If an account has internet disconnected due to non-payment, it can be locked.',
    locked: 'PIN code has been locked.',
    maximumReached: 'Maximum used PIN codes exceeded.',
    maximumReachedWithTitle:
      '{{isp}} has exceeded it’s maximum capacity for used PINs.',
    noCompleteInstallation: 'No, Waiting for Technician',
    pinHasBeenUsed: 'PIN has been marked as used and installation is complete.',
    pinHasBeenUsedWithPrice:
      'PIN has been marked as <1>Used</1> for <3>{{price}}</3> internet and installation is complete.',
    pleaseMarkComplete:
      '<0>Only</0> mark the status of this PIN as used when the customer’s internet has been activated and they have been put into billing.',
    released: 'PIN code has been released and is now available.',
    switchPriceType: 'PIN code is still used but price changed to {{price}}',
    unlocking: 'Unlocking a PIN code will change the status back to used.',
    validAndReserved: 'PIN code is valid and has been reserved for use.',
    yesCompleteInstallation: 'Yes, Mark PIN Code as Used for {{price}}',
  },
  pinStatus: {
    available: 'Available',
    locked: 'Locked',
    reserved: 'Reserved',
    used: 'Used',
  },
  prices: {
    tenDollar: '$10/month',
    twentyDollar: '$20/month',
  },
  reminders: {
    paragraph1:
      'If their code is 9 characters, <2>only enter the first 8 characters</2>.',
    paragraph2: 'If their code is 8 characters, enter all of them.',
  },
  affiliatesTool: {
    title: 'Affiliate Tool',
    enterPin: 'Enter a PIN',
    noApplicationFound: 'No application found',
    pinReport: 'Pin Report',
    mailingAddress: 'Mailing Address',
    mailingAddressExplained:
      'The address where the beneficiary received the letter.',
    serviceAddress: 'Service Address',
    serviceAddressExplained:
      'The address where the beneficiary is connected to Internet. ',
    lineOne: 'Address',
    lineTwo: 'Apartment, suite, unit, building, floor, etc.',
    city: 'City',
    province: 'Province',
    postalCode: 'Postal Code',
    description: 'Use this tool to confirm the caller is a beneficiary.',
  },
  navigation: {
    enterNewPin: 'Enter a New PIN Code',
    updatePin: 'Update a PIN',
    uploadCsvFile: 'Upload CSV File',
    pinTool: 'PIN History',
    pinLookup: 'PIN Lookup',
    affiliatesTool: 'Affiliate Tool',
  },
};

export default en;
