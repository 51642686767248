import { FormikProps, withFormik } from 'formik';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Form, Button, Icon, Radio } from 'semantic-ui-react';
import ReactFileReader from 'react-file-reader';
import * as Papa from 'papaparse';
import { Trans, withNamespaces, WithNamespaces } from 'react-i18next';
import {
  bulkUse,
  bulkReserve,
  bulkLock,
  bulkRelease,
  setBulkUpdateForm,
} from '../../actions/pins';

import './CSVUpload.css';

interface IFormValues {
  updateType?: 'use' | 'reserve' | 'lock' | 'release';
  file?: { name: string; data: Array<{ pin: string; postalCode?: string }> };
}

interface IIspPinEntryScreenProps extends WithNamespaces {
  bulkUse: any;
  bulkReserve: any;
  bulkLock: any;
  bulkRelease: any;
  setBulkUpdateForm: any;
}

const CSVUploadForm = (
  props: FormikProps<IFormValues> & IIspPinEntryScreenProps & WithNamespaces
) => {
  const { values, setFieldValue, isSubmitting, handleSubmit, t } = props;
  const possibleHeaders = new Set([
    'pin',
    'price',
    'postal code',
    'nip',
    'code postaux',
    'prix',
  ]);
  return (
    <div>
      <p>
        <Trans i18nKey="bulk.explanation.paragraph1">
          Bulk status updates of PINs can be performed by uploading a csv file
          in the format:
          <strong> PIN, Postal Code, Price;</strong>
        </Trans>
      </p>
      <ul>
        <li>
          <Trans i18nKey="bulk.explanation.paragraph2">
            <strong>Postal codes</strong> are required for marking PINs as{' '}
            <strong>Reserved.</strong>
          </Trans>
        </li>
        <li>
          <Trans i18nKey="bulk.explanation.paragraph3">
            <strong>Prices</strong> (20 or 10) are required for marking PINs as{' '}
            <strong>Used.</strong>
          </Trans>
        </li>
      </ul>
      <Trans i18nKey="bulk.explanation.paragraph4">
        For example: <strong>AB BB BB B2, M2R 1B1, 20</strong>
      </Trans>

      <Form size="large" onSubmit={handleSubmit}>
        <ReactFileReader
          elementId="csv-upload"
          fileTypes={['.csv']}
          multipleFiles={false}
          base64={true}
          disabled={Boolean(values.file)}
          handleFiles={({
            fileList,
            base64,
          }: {
            fileList: any;
            base64: string;
          }) => {
            const csvString = atob(base64.split(',')[1]);
            let parsedData = Papa.parse(csvString, {
              skipEmptyLines: true,
            }).data;
            const firstRow = parsedData[0];
            if (
              possibleHeaders.has(firstRow[0].toLowerCase()) ||
              (firstRow[1] && possibleHeaders.has(firstRow[1].toLowerCase())) ||
              (firstRow[2] && possibleHeaders.has(firstRow[2].toLowerCase()))
            ) {
              parsedData = parsedData.slice(1);
            }
            const formattedData = parsedData.map((row) => ({
              pin: row[0],
              postalCode: row[1] && row[1].trim(),
              price: row[2] && row[2].trim(),
            }));
            return setFieldValue('file', {
              data: formattedData,
              name: fileList[0].name,
            });
          }}
        >
          {values.file ? (
            <p>
              <i>{values.file.name}</i>
            </p>
          ) : (
            <>
              <a
                href=""
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <Icon size="large" name="add circle" />{' '}
                <b>
                  <label htmlFor="csv-upload">
                    {props.t('buttons.addCsv')}
                  </label>
                </b>
              </a>
            </>
          )}
        </ReactFileReader>
        <br />
        <Form.Field>
          <Radio
            label={{
              children: (
                <Trans i18nKey="bulk.markReserved">
                  Mark all PIN codes as <strong>Reserved.</strong>
                </Trans>
              ),
            }}
            value="reserve"
            checked={values.updateType === 'reserve'}
            onChange={(_, { value }) => {
              setFieldValue('updateType', value);
            }}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label={{
              children: (
                <Trans i18nKey="bulk.markUsed">
                  Mark all PIN codes as <strong>Used.</strong>
                </Trans>
              ),
            }}
            value="use"
            checked={values.updateType === 'use'}
            onChange={(_, { value }) => {
              setFieldValue('updateType', value);
            }}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label={{
              children: (
                <Trans i18nKey="bulk.markLocked">
                  Mark all PIN codes as <strong>Locked.</strong>
                </Trans>
              ),
            }}
            value="lock"
            checked={values.updateType === 'lock'}
            onChange={(_, { value }) => {
              setFieldValue('updateType', value);
            }}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label={{
              children: (
                <Trans i18nKey="bulk.markReleased">
                  Mark all PIN codes as <strong>Released</strong> and available
                  again.
                </Trans>
              ),
            }}
            value="release"
            checked={values.updateType === 'release'}
            onChange={(_, { value }) => {
              setFieldValue('updateType', value);
            }}
          />
        </Form.Field>

        <Button
          disabled={!values.updateType || !values.file}
          size="large"
          color="blue"
          loading={isSubmitting}
          type="submit"
        >
          {t('buttons.uploadFile')}
        </Button>
      </Form>
    </div>
  );
};

type IEnhancedFormProps = IIspPinEntryScreenProps & RouteComponentProps<{}>;

const EnhancedCSVUploadForm = withFormik<IEnhancedFormProps, IFormValues>({
  displayName: 'CSVUploadForm',
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      const data = values.file && values.file.data;
      props.setBulkUpdateForm(values); // Persisting for later use on confirmation screen

      await new Promise((resolve, reject) => {
        switch (values.updateType) {
          case 'use': {
            props.bulkUse(data, true, { reject, resolve });
            break;
          }
          case 'reserve': {
            props.bulkReserve(data, true, { reject, resolve });
            break;
          }
          case 'lock': {
            props.bulkLock(data, true, { reject, resolve });
            break;
          }
          case 'release': {
            props.bulkRelease(data, true, { reject, resolve });
            break;
          }
        }
      });

      setSubmitting(false);
    } catch (err) {
      setSubmitting(false);
      throw err;
    }
  },
  mapPropsToValues: () => ({ updateType: undefined, file: undefined }),
})(CSVUploadForm);

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      bulkLock,
      bulkRelease,
      bulkReserve,
      bulkUse,
      setBulkUpdateForm,
    },
    dispatch
  );
};

export const CSVUpload = connect(
  null,
  mapDispatchToProps
)(withRouter(withNamespaces()(EnhancedCSVUploadForm)));
