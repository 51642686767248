import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withNamespaces, WithNamespaces, Trans } from 'react-i18next';
import { Button, Confirm, Icon } from 'semantic-ui-react';
import { release, use, Price } from '../../actions/pins';
import { IAppState } from '../../reducers';
import PinBubble from '../PinBubble';
import CommentSection from '../comments/CommentSection';
import ButtonWithDesc from '../ButtonWithDesc';
import jwt_decode, { JwtPayload } from 'jwt-decode';

import './PinStatusReservedScreen.css';

interface IPinStatusReservedScreenProps {
  release: any;
  use: any;
}

interface IStateProps {
  justReserved?: boolean;
  loadingRelease: boolean;
  loadingUse: boolean;
  locale: string;
  maximumReached?: boolean;
  normalizedPin?: string;
  postalCode?: string;
  token?: string;
}

const initialState: {
  confirmIsOpen: boolean;
  price: Price;
} = {
  confirmIsOpen: false,
  price: '10',
};

type State = Readonly<typeof initialState>;

class PinStatusReservedScreen extends React.Component<
  IPinStatusReservedScreenProps & IStateProps & WithNamespaces,
  State
> {
  public readonly state: State = initialState;

  public render() {
    const {
      justReserved,
      maximumReached,
      normalizedPin,
      postalCode,
      loadingUse,
      loadingRelease,
      locale,
      t,
    } = this.props;

    const message = justReserved
      ? t('pinMessages.validAndReserved')
      : t('pinMessages.alreadyReserved');

    const postalCodeInfo =
      postalCode !== undefined
        ? {
            postalCode,
            title: t('general.postalCode'),
          }
        : undefined;

    return (
      <div>
        {maximumReached &&
          this.props.token &&
          renderMaximumReached(
            this.props.token,
            () => t('pinMessages.maximumReached'),
            (isp) => t('pinMessages.maximumReachedWithTitle', { isp })
          )}
        <PinBubble
          valid={true}
          normalizedPin={normalizedPin}
          successMessage={message}
          postalCodeInfo={postalCodeInfo}
        >
          <p>
            {locale === 'fr' ? (
              <Trans i18nKey="pinMessages.pleaseMarkComplete">
                Marquer le statut de ce NIP comme Utilisé
                <strong>seulement</strong> lorsque l’Internet de ce client a été
                activé et inscrit à la facturation
              </Trans>
            ) : (
              <Trans i18nKey="pinMessages.pleaseMarkComplete">
                <strong>Only</strong> mark the status of this PIN as used when
                the customer’s internet has been activated and they have been
                put into billing.
              </Trans>
            )}
          </p>
          <Confirm
            open={this.state.confirmIsOpen}
            cancelButton={
              <Button className="cancel">
                {t('pinMessages.noCompleteInstallation')}
              </Button>
            }
            confirmButton={
              <Button size="medium" fluid={false}>
                {t('pinMessages.yesCompleteInstallation', {
                  price:
                    this.state.price === '20'
                      ? t('prices.twentyDollar')
                      : t('prices.tenDollar'),
                })}
              </Button>
            }
            content={
              <div className="content">
                <Trans i18nKey="pinMessages.confirmInstallation">
                  <strong>Please confirm:</strong>
                  Only mark the status of this PIN as <strong>Used</strong>
                  after the customer’s
                  <strong>
                    {{
                      price:
                        this.state.price === '20'
                          ? t('prices.twentyDollar')
                          : t('prices.tenDollar'),
                    }}
                  </strong>{' '}
                  internet has been activated and they have been put into
                  billing.
                </Trans>
              </div>
            }
            onCancel={this.closeConfirm}
            onConfirm={() => {
              this.closeConfirm();
              this.props.use(normalizedPin, this.state.price);
            }}
          />
          <Button
            size="large"
            color="blue"
            loading={loadingUse}
            onClick={this.openConfirm('20')}
          >
            {t('buttons.completeInstallation20')}
          </Button>
          <div className="or">
            <p>
              <b>or</b>
            </p>
          </div>
          <Button
            size="large"
            color="blue"
            loading={loadingUse}
            onClick={this.openConfirm('10')}
          >
            {t('buttons.completeInstallation10')}
          </Button>
        </PinBubble>

        <ButtonWithDesc
          buttonText={t('buttons.release')}
          loading={loadingRelease}
          onClick={() => this.props.release(normalizedPin)}
        >
          <p>{t('pinMessages.cancelInstallation')}</p>
        </ButtonWithDesc>
        <CommentSection />
      </div>
    );
  }

  private openConfirm = (price: Price) => () =>
    this.setState({ confirmIsOpen: true, price });

  private closeConfirm = () => this.setState({ confirmIsOpen: false });
}

function renderMaximumReached(
  token: string,
  getMessage: () => string,
  getMessageWithTitle: (isp: string) => string
) {
  const decoded_token = jwt_decode<JwtPayload>(token);
  const user = decoded_token.sub;
  const title = user && mapUserToProvider(user);
  return (
    user && (
      <div className="ui error message">
        <div className="maximum-reached">
          <Icon name="exclamation circle" color="red" />
          {title !== undefined ? getMessageWithTitle(title) : getMessage()}
        </div>
      </div>
    )
  );
}

function mapUserToProvider(user: string) {
  switch (user) {
    case 'access':
      return 'Access Communications';
    case 'beanfield':
      return 'Beanfield';
    case 'bell':
      return 'Bell';
    case 'ccap':
      return 'CCAP';
    case 'cogeco':
      return 'Cogeco';
    case 'csur':
      return 'CSUR';
    case 'hay':
      return 'Hay Communications';
    case 'mornington':
      return 'Mornington';
    case 'novus':
      return 'Novus';
    case 'northwestel':
      return 'Northwestel, Inc.';
    case 'rogers':
      return 'Rogers';
    case 'ruralnet':
      return 'Rural Net';
    case 'sasktel':
      return 'Sasktel';
    case 'shaw':
      return 'Rogers';
    case 'tbaytel':
      return 'Tbaytel';
    case 'telus':
      return 'TELUS';
    case 'videotron':
      return 'Videotron';
    case 'westman':
      return 'Westman Media Cooperative';
    case 'quadro':
      return 'Quadro Communication Co-Operative Inc.';
    case 'cfsc-opec':
      return 'CFSC-OPEC';
    case 'silverorange':
      return 'silverorange';
    case 'ywca':
      return 'YWCA';
    default:
      return undefined;
  }
}

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    justReserved: state.justReserved,
    loadingRelease: state.loadingRelease,
    loadingUse: state.loadingUse,
    locale: state.locale,
    maximumReached: state.maximumReached,
    normalizedPin: state.normalizedPin,
    postalCode: state.postalCode,
    token: state.token,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      release,
      use,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(PinStatusReservedScreen));
