import * as React from 'react';
import { connect } from 'react-redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { IAppState } from '../../reducers';
import PinBubble from '../PinBubble';
import { getErrorMessageFromReasonCode } from '../../util/getErrorMessageFromReasonCode';
import { ReasonCodes } from '../../actions/pins';

interface IStateProps {
  badStatus?: string;
  expiresAt?: string;
  goodStatuses?: string[];
  locale: string;
  normalizedPin?: string;
  prefix?: string | string[];
  reasonCode?: ReasonCodes;
}

const PinErrorScreen = (props: IStateProps & WithNamespaces) => {
  const {
    badStatus,
    expiresAt,
    goodStatuses,
    locale,
    prefix,
    reasonCode,
    t,
  } = props;

  return (
    <PinBubble
      valid={false}
      normalizedPin={props.normalizedPin}
      errorMessage={
        reasonCode &&
        getErrorMessageFromReasonCode(t, locale, reasonCode, {
          badStatus,
          expiresAt,
          goodStatuses,
          prefix,
        })
      }
    />
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    badStatus: state.invalidPinBadStatus,
    expiresAt: state.invalidPinExpiresAt,
    goodStatuses: state.invalidPinGoodStatuses,
    locale: state.locale,
    normalizedPin: state.normalizedPin,
    prefix: state.invalidPinPrefix,
    reasonCode: state.invalidPinReasonCode,
  };
};

export default connect(mapStateToProps)(withNamespaces()(PinErrorScreen));
