import * as React from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import SignOutButton from './SignOutButton';
import logo from './title-bar__logo-without-text.png';
import logo2x from './title-bar__logo-without-text@2x.png';

import './NavBar.css';
import { Access } from '../actions/app';

interface INavBar {
  showCSVButton: boolean;
  onCSVClick: () => any;
  onPinToolClick: () => any;
  onFindApplicatopnsClick: () => any;
  onAffiliatesClick: () => any;
  onNewPinClick: () => any;
  locale: string;
  setLocale: (locale: string) => any;
  access: Access;
}

type Component = keyof Access;

const NavBar = (props: INavBar & WithNamespaces) => {
  const affiliateAccessOnly = Object.keys(props.access)
    .filter((component) => component !== 'pinValidation')
    .every((key) => !props.access[key as Component]);

  return (
    <div className="navbar">
      <div className="navbar__left">
        <a
          className="navbar-item navbar__new"
          href=""
          onClick={(e) => {
            e.preventDefault();
            props.onNewPinClick();
          }}
        >
          <img
            alt={props.t('general.connectingFamilies')}
            src={logo}
            width="55"
            height="55"
            srcSet={`${logo}, ${logo2x} 2x`}
            className="title-bar__desktop-logo"
          />
        </a>
      </div>
      <div className="navbar__right">
        {props.access?.ispFlow && (
          <a
            className="navbar-item navbar__new"
            href=""
            onClick={(e) => {
              e.preventDefault();
              props.onNewPinClick();
            }}
          >
            <span>
              {props.showCSVButton
                ? props.t('navigation.enterNewPin')
                : props.t('navigation.updatePin')}
            </span>
          </a>
        )}

        {props.access?.ispFlow && props.showCSVButton && (
          <a
            className="navbar-item navbar__csv"
            href=""
            onClick={(e) => {
              e.preventDefault();
              props.onCSVClick();
            }}
          >
            <span>{props.t('navigation.uploadCsvFile')}</span>
          </a>
        )}
        {props.access?.pinHistory && (
          <a
            className="navbar-item navbar__csv"
            href=""
            onClick={(e) => {
              e.preventDefault();
              props.onPinToolClick();
            }}
          >
            <span>{props.t('navigation.pinTool')}</span>
          </a>
        )}
        {props.access?.pinValidation && !affiliateAccessOnly && (
          <a
            className="navbar-item navbar__csv"
            href=""
            onClick={(e) => {
              e.preventDefault();
              props.onAffiliatesClick();
            }}
          >
            <span>{props.t('navigation.affiliatesTool')}</span>
          </a>
        )}
        {props.access?.pinLookup && (
          <a
            className="navbar-item navbar__csv"
            href=""
            onClick={(e) => {
              e.preventDefault();
              props.onFindApplicatopnsClick();
            }}
          >
            <span>{props.t('navigation.pinLookup')}</span>
          </a>
        )}
        {props.locale === 'en' ? (
          <a
            className="navbar-item navbar__lang"
            href=""
            onClick={(e) => {
              props.setLocale('fr');
              e.preventDefault();
            }}
          >
            <span>Français</span>
          </a>
        ) : (
          <a
            className="navbar-item navbar__lang"
            href=""
            onClick={(e) => {
              props.setLocale('en');
              e.preventDefault();
            }}
          >
            <span>English</span>
          </a>
        )}
        <span className="navbar-item navbar__logout">
          <SignOutButton />
        </span>
      </div>
    </div>
  );
};

export default withNamespaces()(NavBar);
