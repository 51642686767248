import * as React from 'react';
import { ReactElement } from 'react';
import { Button, Grid } from 'semantic-ui-react';

interface IButtonWithDescProps {
  buttonText: ReactElement | string;
  children?: React.ReactNode;
  loading?: boolean;
  onClick?: () => void;
}

const ButtonWithDesc = (props: IButtonWithDescProps) => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={9}>{props.children}</Grid.Column>
        <Grid.Column width={7}>
          <Button
            size="large"
            color="blue"
            fluid={true}
            loading={props.loading}
            onClick={props.onClick}
          >
            {props.buttonText}
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default ButtonWithDesc;
