import * as React from 'react';

interface IPostalCode {
  title: string;
  postalCode?: string;
}

const PostalCode = (props: IPostalCode) => {
  const formattedPostalCode =
    props.postalCode &&
    props.postalCode
      .replace(/[^A-Za-z0-9]/g, '') // strip non-postal-code characters
      .replace(/(.{3})/g, '$1 ') // add non-breaking space separators
      .toUpperCase()
      .trim();

  return (
    <p>
      {props.title}
      {': '}
      <b>
        <span id="postal-code">{formattedPostalCode}</span>
      </b>
    </p>
  );
};

export default PostalCode;
