import * as React from 'react';
import { Component, ReactNode, ErrorInfo } from 'react';
import * as Sentry from '@sentry/browser';

interface IErrorBoundaryProps {
  children?: ReactNode;
}

interface IErrorBoundaryState {
  error: Error | null;
}

export class MultilingualErrorBoundary extends Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { error: null };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error });
    Sentry.configureScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, (errorInfo as any)[key]);
      });
    });
    Sentry.captureException(error);
  }

  public render() {
    if (this.state.error) {
      return (
        <div>
          <p>An unexpected error has occurred.</p>
          <p>Une erreur imprévue s’est produite.</p>
        </div>
      );
    }

    return this.props.children;
  }
}
