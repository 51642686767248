import { combineEpics } from 'redux-observable';
import {
  appInitEpic,
  invalidateTokenEpic,
  receiveTokenEpic,
  setAccessEpic,
} from './auth';
import {
  getStatusEpic,
  reserveEpic,
  useEpic,
  releaseEpic,
  lockEpic,
  bulkUse,
  bulkReserve,
  bulkLock,
  bulkRelease,
  updatePriceEpic,
  getAffiliatesReportEpic,
} from './pins';
import { addStatusLogEpic, getStatusLogsEpic } from './statusLogs';
import {
  codeInvalidTransistion,
  getStatusValidTransition,
  lockValidTransition,
  releaseValidTransition,
  reserveValidTransition,
  useValidTransition,
  bulkUpdateTransistion,
} from './uiTransitions';

export const rootEpic = combineEpics(
  appInitEpic,
  codeInvalidTransistion,
  getStatusEpic,
  getStatusValidTransition,
  invalidateTokenEpic,
  receiveTokenEpic,
  setAccessEpic,
  reserveEpic,
  reserveValidTransition,
  updatePriceEpic,
  useEpic,
  useValidTransition,
  releaseEpic,
  releaseValidTransition,
  lockEpic,
  lockValidTransition,
  addStatusLogEpic,
  getStatusLogsEpic,
  bulkUse,
  bulkReserve,
  bulkLock,
  bulkRelease,
  bulkUpdateTransistion,
  getAffiliatesReportEpic
);
