import { IFormValues, IApplicationWithAddress } from '../components/PinLookup';
const MATCHING_PHONE_SCORE = 10;
const MATCHING_EMAIL_SCORE = 5;
const MATCHING_POSTAL_CODE_SCORE = 1;

//todo: add tests
export function sortByRelevance(
  values: IFormValues,
  list: IApplicationWithAddress[]
) {
  const listWithScores = list.map((item) => {
    const matchingFields = Object.keys(values).reduce(
      (acc: number, current) => {
        if (
          current === 'phone' &&
          values[current] &&
          item[current] &&
          item[current] &&
          item[current]?.slice(-10) === values[current].slice(-10)
        ) {
          return acc + MATCHING_PHONE_SCORE;
        }

        if (current === 'email' && item[current] === values[current]) {
          return acc + MATCHING_EMAIL_SCORE;
        }

        if (
          current === 'postalCode' &&
          item[current] === values[current].split(' ').join('')
        ) {
          return acc + MATCHING_POSTAL_CODE_SCORE;
        }

        return acc;
      },
      0
    );

    return { ...item, matchingFields };
  });

  return listWithScores.sort((a, b) => b.matchingFields - a.matchingFields);
}
