/* Status log related Action Types and Action Creators */

export enum ActionKeys {
  GET_STATUS_LOGS = 'GET_STATUS_LOGS',
  GET_STATUS_LOGS_FULFILLED = 'GET_STATUS_LOGS_FULFILLED',
  GET_STATUS_LOGS_FAIL = 'GET_STATUS_LOGS_FAIL',
  ADD_STATUS_LOG = 'ADD_STATUS_LOG',
  ADD_STATUS_LOG_FULFILLED = 'ADD_STATUS_LOG_FULFILLED',
  ADD_STATUS_LOG_FAIL = 'ADD_STATUS_LOG_FAIL',
}

/* Various interfaces and types */

export interface IStatusLog {
  date: string;
  message?: string;
  newStatus: string;
  oldStatus: string;
  newPrice: string;
  oldPrice: string;
}

export interface IGetStatusLogsResponse {
  logs: IStatusLog[];
  normalizedPin: string;
  status: string;
}

export interface IAddStatusLogResponse {
  code: string;
  normalizedPin: string;
  reason: string;
  status: string;
}

export interface IAPIError {
  message: string;
  status: number;
}

interface IMeta {
  reject: any;
  resolve: any;
}

/* Action Types */

export interface IGetStatusLogsAction {
  type: ActionKeys.GET_STATUS_LOGS;
  pin: string;
}

export interface IGetStatusLogsFulfilledAction {
  type: ActionKeys.GET_STATUS_LOGS_FULFILLED;
  response: IGetStatusLogsResponse;
}

export interface IGetStatusLogsFailAction {
  type: ActionKeys.GET_STATUS_LOGS_FAIL;
  error: IAPIError;
}

export interface IAddStatusLogAction {
  type: ActionKeys.ADD_STATUS_LOG;
  pin: string;
  message: string;
  meta?: IMeta;
}

export interface IAddStatusLogFulfilledAction {
  type: ActionKeys.ADD_STATUS_LOG_FULFILLED;
  response: IAddStatusLogResponse;
}

export interface IAddStatusLogFailAction {
  type: ActionKeys.ADD_STATUS_LOG_FAIL;
  error: IAPIError;
}

export type ActionTypes =
  | IGetStatusLogsAction
  | IGetStatusLogsFulfilledAction
  | IGetStatusLogsFailAction
  | IAddStatusLogAction
  | IAddStatusLogFulfilledAction
  | IAddStatusLogFailAction;

/* Action Creators */

export const getStatusLogs = (pin: string): IGetStatusLogsAction => ({
  pin,
  type: ActionKeys.GET_STATUS_LOGS,
});

export const getStatusLogsFulfilled = (
  response: IGetStatusLogsResponse
): IGetStatusLogsFulfilledAction => ({
  response,
  type: ActionKeys.GET_STATUS_LOGS_FULFILLED,
});

export const getStatusLogsFail = (
  error: IAPIError
): IGetStatusLogsFailAction => ({
  error,
  type: ActionKeys.GET_STATUS_LOGS_FAIL,
});

export const addStatusLog = (
  pin: string,
  message: string,
  meta?: IMeta
): IAddStatusLogAction => ({
  message,
  meta,
  pin,
  type: ActionKeys.ADD_STATUS_LOG,
});

export const addStatusLogFulfilled = (
  response: IAddStatusLogResponse
): IAddStatusLogFulfilledAction => ({
  response,
  type: ActionKeys.ADD_STATUS_LOG_FULFILLED,
});

export const addStatusLogFail = (
  error: IAPIError
): IAddStatusLogFailAction => ({
  error,
  type: ActionKeys.ADD_STATUS_LOG_FAIL,
});
