import * as React from 'react';
import { FormikProps, withFormik } from 'formik';
import { Container, Form, Message, Button } from 'semantic-ui-react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IAppState } from '../reducers';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import MaskedInput from 'react-text-mask';
import { IApplication, getAffiliatesReport } from '../actions/pins';
import * as Yup from 'yup';

import './AffiliateTool.css';
import { resetApp } from '../actions/app';
interface IStateProps {
  affiliatesReport?: {
    pin?: string;
    application?: IApplication;
    code?: string;
    reason?: string;
  };
}

interface IFormValues {
  pin: string;
}
const mask = [/\w/, /\w/, ' ', /\w/, /\w/, ' ', /\w/, /\w/, ' ', /\w/, /\w/];
interface IPinEntryScreenProps extends WithNamespaces {
  getAffiliatesReport: typeof getAffiliatesReport;
  resetApp: typeof resetApp;
}

const AffiliatesTool = (
  props: FormikProps<IFormValues> & IPinEntryScreenProps & IStateProps
) => {
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    t,
    affiliatesReport,
    resetApp,
  } = props;

  React.useEffect(() => {
    resetApp();
  }, [resetApp]);

  return (
    <Container className="AffiliatesTool" text={true}>
      <h1>{t('affiliatesTool.title')}</h1>
      <div>{t('affiliatesTool.description')}</div>

      <Form
        size="large"
        error={true}
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <Form.Field>
          <label htmlFor="pin">{t('affiliatesTool.enterPin')}</label>

          <MaskedInput
            mask={mask}
            guide={false}
            id="pin"
            placeholder=""
            type="text"
            value={values.pin.toUpperCase()}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Message error={true} aria-live="polite" content={errors.pin} />
        </Form.Field>

        <Button type="submit" color="blue" size="large">
          {t('buttons.submit')}
        </Button>
      </Form>

      {affiliatesReport && affiliatesReport.reason && (
        <div className="AffiliateTool__report">
          <h1>{affiliatesReport.reason}</h1>
        </div>
      )}

      {affiliatesReport && affiliatesReport.application && (
        <div className="AffiliateTool__report">
          <h1>
            {t('affiliatesTool.pinReport')}: {affiliatesReport.pin}
          </h1>
          <h2>{t('affiliatesTool.mailingAddress')}</h2>
          <h5>{t('affiliatesTool.mailingAddressExplained')}</h5>

          <>
            <p>
              <span>
                <b>{t('affiliatesTool.lineOne')}: </b>
                {affiliatesReport.application.mailingAddress?.line1}
              </span>
              <br />
              <span>
                <b>{t('affiliatesTool.lineTwo')}: </b>
                {affiliatesReport.application.mailingAddress?.line2 || '-'}
              </span>
              <br />
              <span>
                <b>{t('affiliatesTool.city')}: </b>
                {affiliatesReport.application.mailingAddress?.city}
              </span>
              <br />
              <span>
                <b>{t('affiliatesTool.province')}: </b>
                {affiliatesReport.application.mailingAddress?.regionId}
              </span>
              <br />
              <span>
                <b>{t('affiliatesTool.postalCode')}: </b>
                {affiliatesReport.application.mailingAddress?.postalCode}
              </span>
            </p>
          </>
          <h2>{t('affiliatesTool.serviceAddress')}</h2>
          <h5>{t('affiliatesTool.serviceAddressExplained')}</h5>

          <>
            <p>
              <span>
                <b>{t('affiliatesTool.lineOne')}: </b>
                {affiliatesReport.application.serviceAddress?.line1}
              </span>
              <br />
              <span>
                <b>{t('affiliatesTool.lineTwo')}: </b>
                {affiliatesReport.application.serviceAddress?.line2 || '-'}
              </span>
              <br />
              <span>
                <b>{t('affiliatesTool.city')}: </b>
                {affiliatesReport.application.serviceAddress?.city}
              </span>
              <br />
              <span>
                <b>{t('affiliatesTool.province')}: </b>
                {affiliatesReport.application.serviceAddress?.regionId}
              </span>
              <br />
              <span>
                <b>{t('affiliatesTool.postalCode')}: </b>
                {affiliatesReport.application.serviceAddress?.postalCode}
              </span>
            </p>
          </>
        </div>
      )}
    </Container>
  );
};

const EnhancedForm = withFormik<IPinEntryScreenProps, IFormValues>({
  displayName: 'AffiliatesTool',
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await new Promise((resolve, reject) => {
        props.getAffiliatesReport(values.pin, { reject, resolve });
      });
    } catch (err) {
      setSubmitting(false);
      throw err;
    }
  },
  mapPropsToValues: () => ({ pin: '' }),
  validationSchema: (props: IPinEntryScreenProps) =>
    Yup.object().shape({
      pin: Yup.string().required(props.t('formValidation.pinRequired')),
    }),
})(AffiliatesTool);

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    affiliatesReport: state.affiliatesReport,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getAffiliatesReport,
      resetApp,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(EnhancedForm));
