import * as React from 'react';
import { Button } from 'semantic-ui-react';
import { withNamespaces, WithNamespaces } from 'react-i18next';

class SignOutButton extends React.Component<WithNamespaces, {}> {
  constructor(props: any) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  public render() {
    return (
      <Button color="blue" onClick={this.onClick}>
        {this.props.t('buttons.signOut')}
      </Button>
    );
  }

  private onClick() {
    localStorage.removeItem('token');
    window.location.replace(process.env.REACT_APP_LOGOUT_URL || '');
  }
}

export default withNamespaces()(SignOutButton);
