import * as React from 'react';
import { Icon } from 'semantic-ui-react';

import PostalCodeDisplay from './PostalCodeDisplay';

import './PinBubble.css';

interface IPinBubble {
  normalizedPin?: string;
  valid: boolean;
  successMessage?: string | JSX.Element;
  errorMessage?: string;
  postalCodeInfo?: {
    postalCode: string;
    title: string;
  };
  children?: React.ReactNode;
}

const PinBubble = (props: IPinBubble) => {
  const color = props.valid ? 'green' : 'red';
  const pinDivId = props.valid ? 'valid-pin' : 'invalid-pin';
  const formattedPin =
    props.normalizedPin && props.normalizedPin.replace(/(.{2})/g, '$1 ').trim();

  return (
    <div className={`pin-bubble pin-bubble--${color}`}>
      <div id={pinDivId}>
        <h2>{formattedPin}</h2>
      </div>
      {props.postalCodeInfo && (
        <PostalCodeDisplay
          postalCode={props.postalCodeInfo.postalCode}
          title={props.postalCodeInfo.title}
        />
      )}
      {props.successMessage && (
        <div>
          <Icon name="check circle" color="green" />
          {props.successMessage}
        </div>
      )}
      {props.errorMessage && (
        <div>
          <Icon name="times circle" color="red" />
          {props.errorMessage}
        </div>
      )}
      {props.children && <br />}
      {props.children}
    </div>
  );
};

export default PinBubble;
