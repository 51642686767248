import * as React from 'react';
import { connect } from 'react-redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { IAppState } from '../../reducers';
import PinBubble from '../PinBubble';

interface IStateProps {
  normalizedPin?: string;
}

const PinStatusLockedScreen = (props: IStateProps & WithNamespaces) => {
  return (
    <div>
      <PinBubble
        valid={true}
        normalizedPin={props.normalizedPin}
        successMessage={props.t('pinMessages.released')}
      />
    </div>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    normalizedPin: state.normalizedPin,
  };
};

export default connect(mapStateToProps)(
  withNamespaces()(PinStatusLockedScreen)
);
