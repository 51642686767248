import * as React from 'react';
import moment from 'moment';
import { Grid } from 'semantic-ui-react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import getMessageFromStatus from '../../util/getMessageFromStatus';
import 'moment/locale/en-ca';
import 'moment/locale/fr-ca';

import './index.css';

interface IStatusLog {
  locale: string;
  status: string;
  date: string;
}

const StatusLogImpl = (props: IStatusLog & WithNamespaces) => {
  const { date, locale, t } = props;

  moment.locale(locale);
  const formattedDate = moment(date).format(t('comment.dateFormat'));
  const isoDate = new Date(date).toISOString();

  return (
    <Grid className="status-log">
      <Grid.Column width={11}>
        <small>{props.t(getMessageFromStatus(props.status))}</small>
      </Grid.Column>
      <Grid.Column width={5} textAlign="right">
        <time className="status-log__date" dateTime={isoDate}>
          {formattedDate}
        </time>
      </Grid.Column>
    </Grid>
  );
};

export const StatusLog = withNamespaces()(StatusLogImpl);
