import { FormikProps, withFormik } from 'formik';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Yup from 'yup';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Form, Button } from 'semantic-ui-react';
import MaskedInput from 'react-text-mask';
import { Trans, withNamespaces, WithNamespaces } from 'react-i18next';

import { getStatus } from '../../actions/pins';
import { resetApp } from '../../actions/app';

import './PinEntryScreen.css';

interface IFormValues {
  pin: string;
}

interface IPinEntryScreenProps extends WithNamespaces {
  getStatus: any;
  resetApp: typeof resetApp;
  showCSVUpload: boolean;
}

class PinEntryScreen extends React.Component<
  FormikProps<IFormValues> & IPinEntryScreenProps & WithNamespaces
> {
  public componentDidMount() {
    this.props.resetApp();
  }

  public render() {
    const {
      values,
      touched,
      errors,
      isSubmitting,
      handleChange,
      handleBlur,
      handleSubmit,
      t,
      showCSVUpload,
    } = this.props;

    return (
      <>
        <Form size="large" onSubmit={handleSubmit} autoComplete="off">
          <Form.Field>
            <label htmlFor="pin" style={{ display: 'block' }}>
              {t('labels.enterPinCode')}
            </label>
            <ul>
              <li>
                <Trans i18nKey="reminders.paragraph1">
                  If their code is 9 characters,{' '}
                  <b>only enter the first 8 characters</b>.
                </Trans>
              </li>
              <li>{t('reminders.paragraph2')}</li>
            </ul>
            <MaskedInput
              mask={[
                /\w/,
                /\w/,
                ' ',
                /\w/,
                /\w/,
                ' ',
                /\w/,
                /\w/,
                ' ',
                /\w/,
                /\w/,
              ]}
              guide={false}
              id="pin"
              placeholder=""
              type="text"
              value={values.pin}
              onChange={handleChange}
              onBlur={handleBlur}
              className={
                errors.pin && touched.pin
                  ? 'text-input pin-input error'
                  : 'text-input pin-input'
              }
            />
            {errors.pin && touched.pin && (
              <div className="input-feedback ui message">{errors.pin}</div>
            )}
          </Form.Field>
          <Button
            size="large"
            color="blue"
            loading={isSubmitting}
            type="submit"
          >
            {t('buttons.lookUpPinCode')}
          </Button>
        </Form>
        {showCSVUpload ? (
          // only used by superuser (silverorange) - no need for translation
          <div className="csv-redirect">
            Have a bulk of PINs to change? Use the{' '}
            <a href="/csv-upload">Bulk PINs Upload Tool</a>
          </div>
        ) : null}
      </>
    );
  }
}

type IEnhancedFormProps = IPinEntryScreenProps & RouteComponentProps<{}>;

const EnhancedForm = withFormik<IEnhancedFormProps, IFormValues>({
  displayName: 'PinEntryScreen',
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await new Promise((resolve, reject) => {
        props.getStatus(values.pin, { reject, resolve });
      });
    } catch (err) {
      setSubmitting(false);
      throw err;
    }
  },
  mapPropsToValues: () => ({ pin: '' }),
  validationSchema: (props: IEnhancedFormProps) =>
    Yup.object().shape({
      pin: Yup.string().required(props.t('formValidation.pinRequired')),
    }),
})(PinEntryScreen as any);

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      getStatus,
      resetApp,
    },
    dispatch
  );
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(withNamespaces()(EnhancedForm)));
