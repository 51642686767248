import { SentryEvent } from '@sentry/browser';
import { Middleware } from 'redux';

const identity = (x: any) => x;
const getUndefined = () => null;
const filter = () => true;

interface ISentryForReduxOptions {
  actionTransformer?: any;
  breadcrumbDataFromAction?: any;
  breadcrumbCategory?: string;
  filterBreadcrumbActions?: (x: any) => boolean;
  getUserContext?: any;
  getTags?: any;
  stateTransformer?: any;
}
/**
 * Based off https://github.com/captbaritone/raven-for-redux
 */
export function createSentryMiddleware(
  Sentry: any,
  options: ISentryForReduxOptions = {}
): Middleware {
  const {
    actionTransformer = identity,
    breadcrumbDataFromAction = getUndefined,
    stateTransformer = identity,
    breadcrumbCategory = 'redux-action',
    filterBreadcrumbActions = filter,
    getUserContext,
    getTags,
  } = options;

  return (store) => {
    let lastAction: any;

    const client = Sentry.getCurrentHub().getClient();

    if (client && client.options) {
      client.options.beforeSend = async (event: SentryEvent) => {
        const reduxEvent = event;
        const state = store.getState();
        const reduxExtra = {
          lastAction: actionTransformer(lastAction),
          state: stateTransformer(state),
        };
        reduxEvent.extra = Object.assign(reduxExtra, event.extra);
        if (getUserContext) {
          reduxEvent.user = getUserContext(state);
        }
        if (getTags) {
          reduxEvent.tags = getTags(state);
        }
        return reduxEvent;
      };
    }

    return (next) => (action) => {
      // Log the action taken to Sentry so that we have narrative context in
      // our error report.
      if (filterBreadcrumbActions(action)) {
        Sentry.addBreadcrumb({
          category: breadcrumbCategory,
          data: breadcrumbDataFromAction(action),
          message: action.type,
        });
      }

      lastAction = action;
      return next(action);
    };
  };
}
