import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Price, use } from '../../actions/pins';
import { IAppState } from '../../reducers';
import PinBubble from '../PinBubble';
import ButtonWithDesc from '../ButtonWithDesc';
import CommentSection from '../comments/CommentSection';

interface IPinStatusLockedScreenProps {
  use: any;
}

interface IStateProps {
  loadingUse: boolean;
  normalizedPin?: string;
  price?: Price;
  postalCode?: string;
}

const PinStatusLockedScreen = (
  props: IPinStatusLockedScreenProps & IStateProps & WithNamespaces
) => {
  const { t, postalCode, price } = props;

  const postalCodeInfo =
    postalCode !== undefined
      ? {
          postalCode,
          title: t('general.postalCode'),
        }
      : undefined;

  return (
    <div>
      <PinBubble
        valid={true}
        normalizedPin={props.normalizedPin}
        successMessage={t('pinMessages.locked')}
        postalCodeInfo={postalCodeInfo}
      />
      <ButtonWithDesc
        buttonText={t('buttons.unlock')}
        loading={props.loadingUse}
        onClick={() => props.use(props.normalizedPin, price ? price : '10')}
      >
        <p>{t('pinMessages.unlocking')}</p>
      </ButtonWithDesc>
      <CommentSection />
    </div>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    loadingUse: state.loadingUse,
    normalizedPin: state.normalizedPin,
    price: state.price,
    postalCode: state.postalCode,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      use,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(PinStatusLockedScreen));
