import { ignoreElements, map, mergeMap, tap, catchError } from 'rxjs/operators';
import { ActionsObservable, StateObservable } from 'redux-observable';
import {
  ActionKeys,
  ActionTypes,
  IReceiveToken,
  noop,
  receiveToken,
  setLocale,
  setAccess,
  IInvalidateToken,
  IAppInitAction,
  ISetAccess,
  invalidateToken,
} from '../actions/app';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { API_VERSION } from '../api/version';
import { IAppState } from '../reducers';
import { IAPIError } from '../actions/pins';

export const appInitEpic = (action$: ActionsObservable<ActionTypes>) =>
  action$.ofType<IAppInitAction>(ActionKeys.APP_INIT).pipe(
    mergeMap((value, index) => {
      const token = localStorage.getItem('token');
      const actions = token
        ? [
            receiveToken(token),
            setLocale(window.navigator.language),
            setAccess(),
          ]
        : [noop()];

      return of(...actions);
    })
  );

export const receiveTokenEpic = (action$: ActionsObservable<ActionTypes>) =>
  action$.ofType<IReceiveToken>(ActionKeys.RECEIVE_TOKEN).pipe(
    tap((action) => {
      if (action.token) {
        localStorage.setItem('token', action.token);
      }
    }),
    ignoreElements()
  );

export const invalidateTokenEpic = (action$: ActionsObservable<ActionTypes>) =>
  action$.ofType<IInvalidateToken>(ActionKeys.INVALIDATE_TOKEN).pipe(
    map(() => {
      localStorage.removeItem('token');
      return noop();
    })
  );

const getAccessEndpoint =
  process.env.REACT_APP_API_BASE_URL + '/pin/get-user-access';

export const setAccessEpic = (
  action$: ActionsObservable<ActionTypes>,
  state$: StateObservable<IAppState>
) =>
  action$.ofType<ISetAccess>(ActionKeys.SET_ACCESS).pipe(
    mergeMap(() =>
      ajax
        .post(
          getAccessEndpoint,
          {},
          {
            Accept: API_VERSION,
            'Accept-Language': state$.value.locale,
            Authorization: `Bearer ${state$.value.token}`,
          }
        )
        .pipe(
          map((event) => {
            const response = event.response;

            if (state$.value.access !== undefined) {
              return noop();
            }

            if (response.access === null) {
              return noop();
            }

            return setAccess({
              ispFlow: response.access.ispFlow,
              pinHistory: response.access.pinHistory,
              pinLookup: response.access.pinLookup,
              pinValidation: response.access.pinValidation,
              report: response.access.report,
            });
          }),
          catchError((error: IAPIError): any => {
            if (error.status === 401) {
              return of(invalidateToken());
            }
            return setAccess();
          })
        )
    )
  );
