export default function getMessageFromPrice(price: string): string {
  switch (price) {
    case '20': {
      return 'prices.twentyDollar';
    }
    case '10': {
      return 'prices.tenDollar';
    }
    default:
      return '';
  }
}
