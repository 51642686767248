import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Formik, FormikErrors } from 'formik';
import {
  Container,
  Form,
  Message,
  Button,
  Input,
  Divider,
} from 'semantic-ui-react';
import { ajax } from 'rxjs/ajax';
import validator from 'validator';
import MaskedInput from 'react-text-mask';
import _ from 'lodash';
import moment from 'moment';
import { IAppState } from '../reducers';
import { invalidateToken } from '../actions/app';
import { sortByRelevance } from '../util/sortByRelevance';
import './PinLookup.css';

const matchingPhoneScores = [10, 11, 15, 16];
const matchingEmailScores = [5, 6, 15, 16];
const matchingPostalCodeScores = [1, 6, 11, 16];
export interface IApplicationWithAddress {
  id: string;
  pinId: string;
  city: string;
  line1: string;
  line2: string | null;
  regionId: string;
  postalCode: string;
  computerAddressId: string | null;
  mailingAddressId: string | null;
  serviceAddressId: string;
  languageId: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phone: string | null;
  provider: string;
  providerInternet: boolean;
  createdAt: string;
  updatedAt: string;
  matchingFields: number;
}

interface IPinLookupReportProps {
  applications: IApplicationWithAddress[];
}

const PinLookupReport: React.SFC<IPinLookupReportProps> = ({
  applications,
}) => {
  if (!applications.length) {
    return (
      <>
        <h2>0 matches</h2>
        <p>There might be no matching application.</p>
      </>
    );
  }

  moment.locale('en');

  return (
    <>
      <div className="PinLookup__results">
        <h5>
          {applications.length} potential match
          {applications.length > 1 ? 'es' : ''}
        </h5>
        <div>
          <b>Tip:</b> Use Cmd+F on Mac or Ctrl+F on Windows to search for an
          address across many possible matches.
        </div>
      </div>
      {applications.map((application, index) => (
        <div className="PinLookup__report" key={application.id}>
          <b>
            Application {index + 1} of {applications.length}
          </b>
          <Divider />
          <div>
            <div>
              <b>Email: </b>{' '}
              <span
                className={
                  matchingEmailScores.includes(application.matchingFields)
                    ? 'PinLookup__report--highlighted'
                    : ''
                }
              >
                {application.email ? application.email : '-'}
              </span>
            </div>
            <div>
              <b>Phone: </b>{' '}
              <span
                className={
                  matchingPhoneScores.includes(application.matchingFields)
                    ? 'PinLookup__report--highlighted'
                    : ''
                }
              >
                {application.phone ? application.phone : '-'}
              </span>
            </div>
            <div>
              <b>Address: </b> {application.line1},{' '}
              {application.line2 && application.line2}, {application.city},{' '}
              {application.regionId}
            </div>
            <div>
              <b>Postal Code: </b>{' '}
              <span
                className={
                  matchingPostalCodeScores.includes(application.matchingFields)
                    ? 'PinLookup__report--highlighted'
                    : ''
                }
              >
                {application.postalCode}
              </span>
            </div>
            <div>
              <b>Provider: </b> {_.upperFirst(application.provider)}
            </div>
          </div>
          <p>
            <b>
              PIN:{' '}
              <span className="PinLookup__pin">
                {application.pinId.replace(/(\w{2})(?=\w)/g, '$1 ')}
              </span>
            </b>
          </p>
        </div>
      ))}
    </>
  );
};

export interface IFormValues {
  phone: string;
  email: string;
  postalCode: string;
}

interface IStateProps {
  token?: string;
}

interface IDispatchProps {
  invalidateToken: typeof invalidateToken;
}

interface IOwnState {
  loading: boolean;
  applications?: IApplicationWithAddress[];
  error?: string;
}

type Props = IStateProps &
  IDispatchProps & {
    ownUrlForPinTool?: boolean;
  };

class PinLookupImpl extends React.Component<Props, IOwnState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: undefined,
      loading: false,
      applications: undefined,
    };

    this.fetchData = this.fetchData.bind(this);
  }

  public render() {
    return (
      <Container className="PinLookup" text={true}>
        <h1>PIN Lookup Tool</h1>

        <Formik<IFormValues>
          initialValues={{
            phone: '',
            email: '',
            postalCode: '',
          }}
          onSubmit={(values) => {
            this.fetchData(values);
          }}
          validate={(values) => {
            const errors: FormikErrors<IFormValues> = {};

            if (
              values.phone &&
              !validator.isMobilePhone(values.phone, 'en-CA')
            ) {
              errors.phone = 'Invalid phone number';
            }
            if (values.email && !validator.isEmail(values.email)) {
              errors.email = 'Invalid email';
            }

            const postalCode = values.postalCode;

            if (postalCode && !validator.isPostalCode(postalCode, 'CA')) {
              errors.postalCode = 'Invalid postal code';
            }

            if (!postalCode && !values.phone && !values.email) {
              errors.postalCode = 'Please fill in at least one field';
            }
            return errors;
          }}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({ values, errors, handleChange, handleBlur, handleSubmit }) => {
            return (
              <Form
                size="large"
                error={true}
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <Form.Field>
                  <label htmlFor="phone">Mobile phone number</label>
                  <Input
                    id="phone"
                    type="tel"
                    value={values.phone}
                    onChange={handleChange}
                  />
                  <Message
                    error={true}
                    aria-live="polite"
                    content={errors.phone}
                  />
                </Form.Field>
                <Form.Field>
                  <label htmlFor="email">Email address</label>

                  <Input
                    id="email"
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                  />
                  <Message
                    error={true}
                    aria-live="polite"
                    content={errors.email}
                  />
                </Form.Field>
                <Form.Field>
                  <label htmlFor="postalCode">Postal code</label>

                  <MaskedInput
                    mask={postalCodeMask}
                    guide={false}
                    id="postalCode"
                    type="text"
                    value={values.postalCode.toUpperCase()}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-label={'Postal Code'}
                    aria-required={true}
                    aria-invalid={errors.postalCode ? true : false}
                  />
                  <Message
                    error={true}
                    aria-live="polite"
                    content={errors.postalCode ? errors.postalCode : null}
                    role="alert"
                  />
                </Form.Field>

                <Button loading={this.state.loading} type="submit">
                  Submit
                </Button>
              </Form>
            );
          }}
        </Formik>
        {this.state.error && <h2>{this.state.error}</h2>}
        <p className="PinLookup__hint">
          Looking for info on a PIN? Use the{' '}
          <a href={this.props.ownUrlForPinTool ? '/pin-tool' : '/'}>
            PIN History
          </a>
        </p>
        {this.state.applications && (
          <PinLookupReport applications={this.state.applications} />
        )}
      </Container>
    );
  }

  private fetchData(values: IFormValues) {
    const endpoint = process.env.REACT_APP_API_BASE_URL + '/pin/find-pin';

    this.setState({
      error: undefined,
      loading: true,
      applications: [],
    });

    ajax
      .post(endpoint, values, {
        Authorization: `Bearer ${this.props.token}`,
        'Content-Type': 'application/json',
      })
      .subscribe(
        (result) => {
          const applications = result.response;
          const sorted = sortByRelevance(values, applications);
          this.setState({
            loading: false,
            applications: sorted,
          });
        },
        (error) => {
          if (error.status === 401) {
            return this.props.invalidateToken();
          } else if (error.status === 403) {
            return this.setState({
              loading: false,
              error: "You don't have access to reports",
            });
          } else {
            throw new Error(error);
          }
        }
      );
  }
}

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    token: state.token,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      invalidateToken,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PinLookupImpl);

const postalCodeMask: Array<RegExp | string> = [
  /\w/,
  /\w/,
  /\w/,
  ' ',
  /\w/,
  /\w/,
  /\w/,
];
