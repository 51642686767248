export default function getMessageFromStatus(status: string): string {
  switch (status) {
    case 'available': {
      return 'pinMessages.released';
    }

    case 'reserved': {
      return 'pinMessages.validAndReserved';
    }

    case 'used': {
      return 'pinMessages.pinHasBeenUsed';
    }

    case 'locked': {
      return 'pinMessages.locked';
    }

    default:
      return '';
  }
}
