import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { IAppState } from '../reducers';
import {
  ActionKeys,
  ActionTypes,
  codeInvalidResponse,
  IGetStatusAction,
  IReserveAction,
  getStatusFail,
  getStatusValid,
  reserveFail,
  reserveValid,
  reserveInvalid,
  singleUseFail,
  singleUseValid,
  releaseFail,
  releaseValid,
  lockFail,
  lockValid,
  updatePriceFail,
  updatePriceValid,
  IAPIError,
  ResponseCodes,
  IMutationAPIResponse,
  INoMutationAPIResponse,
  IPriceMutationAPIResponse,
  IUseAction,
  IReleaseAction,
  ILockAction,
  IUpdatePriceAction,
  bulkUseValid,
  bulkReserveValid,
  bulkLockValid,
  bulkReleaseValid,
  bulkUseFail,
  bulkReserveFail,
  bulkLockFail,
  bulkReleaseFail,
  IBulkUse,
  IBulkReserve,
  IBulkLock,
  IBulkRelease,
  IBulkUpdateResponse,
  IGetAffiliatesReportAction,
  getAffiliatesReportFulfilled,
  IGetAffiliatesReportResponse,
} from '../actions/pins';

import { invalidateToken } from '../actions/app';
import { API_VERSION } from '../api/version';

const getStatusEndpoint =
  process.env.REACT_APP_API_BASE_URL + '/pin/get-status';

export const getStatusEpic = (
  action$: ActionsObservable<ActionTypes>,
  state$: StateObservable<IAppState>
) =>
  action$.ofType<IGetStatusAction>(ActionKeys.GET_STATUS).pipe(
    mergeMap((action: IGetStatusAction) =>
      ajax
        .post(
          getStatusEndpoint,
          { pin: action.pin, getServicePostalCode: true },
          {
            Accept: API_VERSION,
            'Accept-Language': state$.value.locale,
            Authorization: `Bearer ${state$.value.token}`,
          }
        )
        .pipe(
          map((event) => {
            const response: INoMutationAPIResponse = event.response;
            if (action.meta) {
              action.meta.resolve();
            }
            if (
              response.code === ResponseCodes.VALID ||
              response.code === ResponseCodes.MAXIMUM_REACHED
            ) {
              return getStatusValid(response);
            } else {
              return codeInvalidResponse(response);
            }
          }),
          catchError((error: IAPIError): any => {
            if (error.status === 401) {
              return of(invalidateToken());
            }
            if (action.meta) {
              action.meta.reject(error);
            }
            return of(getStatusFail(error));
          })
        )
    )
  );

const reserveEndpoint = process.env.REACT_APP_API_BASE_URL + '/pin/reserve';

export const reserveEpic = (
  action$: ActionsObservable<ActionTypes>,
  state$: StateObservable<IAppState>
) =>
  action$.ofType<IReserveAction>(ActionKeys.RESERVE).pipe(
    mergeMap((action) =>
      ajax
        .post(
          reserveEndpoint,
          { pin: action.pin, postalCode: action.postalCode },
          {
            Accept: API_VERSION,
            'Accept-Language': state$.value.locale,
            Authorization: `Bearer ${state$.value.token}`,
          }
        )
        .pipe(
          map((event) => {
            if (action.meta) {
              action.meta.resolve();
            }
            if (
              event.response.code === ResponseCodes.VALID ||
              event.response.code === ResponseCodes.MAXIMUM_REACHED
            ) {
              const response: IMutationAPIResponse = event.response;
              return reserveValid(response);
            } else {
              const response: INoMutationAPIResponse = event.response;
              return reserveInvalid(response);
            }
          }),
          catchError((error: IAPIError): any => {
            if (error.status === 401) {
              return of(invalidateToken());
            }
            if (action.meta) {
              action.meta.reject(error);
            }
            return of(reserveFail(error));
          })
        )
    )
  );

const updatePriceEndpoint =
  process.env.REACT_APP_API_BASE_URL + '/pin/update-price';

export const updatePriceEpic = (
  action$: ActionsObservable<ActionTypes>,
  state$: StateObservable<IAppState>
) =>
  action$.ofType<IUpdatePriceAction>(ActionKeys.UPDATE_PRICE).pipe(
    mergeMap((action) =>
      ajax
        .post(
          updatePriceEndpoint,
          { pin: action.pin, price: action.price },
          {
            Accept: API_VERSION,
            'Accept-Language': state$.value.locale,
            Authorization: `Bearer ${state$.value.token}`,
          }
        )
        .pipe(
          map((event) => {
            if (action.meta) {
              action.meta.resolve();
            }
            if (event.response.code === ResponseCodes.VALID) {
              const response: IPriceMutationAPIResponse = event.response;
              return updatePriceValid(response);
            } else {
              const response: INoMutationAPIResponse = event.response;
              return codeInvalidResponse(response);
            }
          }),
          catchError((error: IAPIError): any => {
            if (error.status === 401) {
              return of(invalidateToken());
            }
            if (action.meta) {
              action.meta.reject(error);
            }
            return of(updatePriceFail(error));
          })
        )
    )
  );

const useEndpoint = process.env.REACT_APP_API_BASE_URL + '/pin/use';

export const useEpic = (
  action$: ActionsObservable<ActionTypes>,
  state$: StateObservable<IAppState>
) =>
  action$.ofType<IUseAction>(ActionKeys.USE).pipe(
    mergeMap((action) =>
      ajax
        .post(
          useEndpoint,
          { pin: action.pin, price: action.price },
          {
            Accept: API_VERSION,
            'Accept-Language': state$.value.locale,
            Authorization: `Bearer ${state$.value.token}`,
          }
        )
        .pipe(
          map((event) => {
            if (event.response.code === ResponseCodes.VALID) {
              const response: IMutationAPIResponse = event.response;
              return singleUseValid(response);
            } else if (event.response.code === ResponseCodes.MAXIMUM_REACHED) {
              const response: IMutationAPIResponse = event.response;
              return singleUseValid(response);
            } else {
              const response: INoMutationAPIResponse = event.response;
              return codeInvalidResponse(response);
            }
          }),
          catchError((error: IAPIError): any => {
            if (error.status === 401) {
              return of(invalidateToken());
            }
            return of(singleUseFail(error));
          })
        )
    )
  );

const releaseEndpoint = process.env.REACT_APP_API_BASE_URL + '/pin/release';

export const releaseEpic = (
  action$: ActionsObservable<ActionTypes>,
  state$: StateObservable<IAppState>
) =>
  action$.ofType<IReleaseAction>(ActionKeys.RELEASE).pipe(
    mergeMap((action) =>
      ajax
        .post(
          releaseEndpoint,
          { pin: action.pin },
          {
            Accept: API_VERSION,
            'Accept-Language': state$.value.locale,
            Authorization: `Bearer ${state$.value.token}`,
          }
        )
        .pipe(
          map((event) => {
            if (event.response.code === ResponseCodes.VALID) {
              const response: IMutationAPIResponse = event.response;
              return releaseValid(response);
            } else {
              const response: INoMutationAPIResponse = event.response;
              return codeInvalidResponse(response);
            }
          }),
          catchError((error: IAPIError): any => {
            if (error.status === 401) {
              return of(invalidateToken());
            }
            return of(releaseFail(error));
          })
        )
    )
  );

const lockEndpoint = process.env.REACT_APP_API_BASE_URL + '/pin/lock';

export const lockEpic = (
  action$: ActionsObservable<ActionTypes>,
  state$: StateObservable<IAppState>
) =>
  action$.ofType<ILockAction>(ActionKeys.LOCK).pipe(
    mergeMap((action) =>
      ajax
        .post(
          lockEndpoint,
          { pin: action.pin },
          {
            Accept: API_VERSION,
            'Accept-Language': state$.value.locale,
            Authorization: `Bearer ${state$.value.token}`,
          }
        )
        .pipe(
          map((event) => {
            if (event.response.code === ResponseCodes.VALID) {
              const response: IMutationAPIResponse = event.response;
              return lockValid(response);
            } else if (event.response.code === ResponseCodes.MAXIMUM_REACHED) {
              const response: IMutationAPIResponse = event.response;
              return lockValid(response);
            } else {
              const response: INoMutationAPIResponse = event.response;
              return codeInvalidResponse(response);
            }
          }),
          catchError((error: IAPIError): any => {
            if (error.status === 401) {
              return of(invalidateToken());
            }
            return of(lockFail(error));
          })
        )
    )
  );

const bulkUseEndpoint = process.env.REACT_APP_API_BASE_URL + '/pin/bulk-use';

export const bulkUse = (
  action$: ActionsObservable<ActionTypes>,
  state$: StateObservable<IAppState>
) =>
  action$.ofType<IBulkUse>(ActionKeys.BULK_USE).pipe(
    mergeMap((action) =>
      ajax
        .post(
          bulkUseEndpoint,
          { data: action.data, dryRun: action.dryRun },
          {
            Accept: API_VERSION,
            'Accept-Language': state$.value.locale,
            Authorization: `Bearer ${state$.value.token}`,
            'Content-Type': 'application/json',
          }
        )
        .pipe(
          map((event) => {
            if (action.meta) {
              action.meta.resolve();
            }
            const response: IBulkUpdateResponse = event.response;
            return bulkUseValid(response);
          }),
          catchError((error: IAPIError): any => {
            if (action.meta) {
              action.meta.reject(error);
            }
            if (error.status === 401) {
              return of(invalidateToken());
            }
            return of(bulkUseFail(error));
          })
        )
    )
  );

const bulkReserveEndpoint =
  process.env.REACT_APP_API_BASE_URL + '/pin/bulk-reserve';

export const bulkReserve = (
  action$: ActionsObservable<ActionTypes>,
  state$: StateObservable<IAppState>
) =>
  action$.ofType<IBulkReserve>(ActionKeys.BULK_RESERVE).pipe(
    mergeMap((action) =>
      ajax
        .post(
          bulkReserveEndpoint,
          { data: action.data, dryRun: action.dryRun },
          {
            Accept: API_VERSION,
            'Accept-Language': state$.value.locale,
            Authorization: `Bearer ${state$.value.token}`,
            'Content-Type': 'application/json',
          }
        )
        .pipe(
          map((event) => {
            if (action.meta) {
              action.meta.resolve();
            }
            const response: IBulkUpdateResponse = event.response;
            return bulkReserveValid(response);
          }),
          catchError((error: IAPIError): any => {
            if (action.meta) {
              action.meta.reject(error);
            }
            if (error.status === 401) {
              return of(invalidateToken());
            }
            return of(bulkReserveFail(error));
          })
        )
    )
  );

const bulkLockEndpoint = process.env.REACT_APP_API_BASE_URL + '/pin/bulk-lock';

export const bulkLock = (
  action$: ActionsObservable<ActionTypes>,
  state$: StateObservable<IAppState>
) =>
  action$.ofType<IBulkLock>(ActionKeys.BULK_LOCK).pipe(
    mergeMap((action) =>
      ajax
        .post(
          bulkLockEndpoint,
          { data: action.data, dryRun: action.dryRun },
          {
            Accept: API_VERSION,
            'Accept-Language': state$.value.locale,
            Authorization: `Bearer ${state$.value.token}`,
            'Content-Type': 'application/json',
          }
        )
        .pipe(
          map((event) => {
            if (action.meta) {
              action.meta.resolve();
            }
            const response: IBulkUpdateResponse = event.response;
            return bulkLockValid(response);
          }),
          catchError((error: IAPIError): any => {
            if (action.meta) {
              action.meta.reject(error);
            }
            if (error.status === 401) {
              return of(invalidateToken());
            }
            return of(bulkLockFail(error));
          })
        )
    )
  );

const bulkReleaseEndpoint =
  process.env.REACT_APP_API_BASE_URL + '/pin/bulk-release';

export const bulkRelease = (
  action$: ActionsObservable<ActionTypes>,
  state$: StateObservable<IAppState>
) =>
  action$.ofType<IBulkRelease>(ActionKeys.BULK_RELEASE).pipe(
    mergeMap((action) =>
      ajax
        .post(
          bulkReleaseEndpoint,
          { data: action.data, dryRun: action.dryRun },
          {
            Accept: API_VERSION,
            'Accept-Language': state$.value.locale,
            Authorization: `Bearer ${state$.value.token}`,
            'Content-Type': 'application/json',
          }
        )
        .pipe(
          map((event) => {
            if (action.meta) {
              action.meta.resolve();
            }
            const response: IBulkUpdateResponse = event.response;
            return bulkReleaseValid(response);
          }),
          catchError((error: IAPIError): any => {
            if (action.meta) {
              action.meta.reject(error);
            }
            if (error.status === 401) {
              return of(invalidateToken());
            }
            return of(bulkReleaseFail(error));
          })
        )
    )
  );

const getAffiliatesReportEndpoint =
  process.env.REACT_APP_API_BASE_URL + '/pin/validate-pin-address';

export const getAffiliatesReportEpic = (
  action$: ActionsObservable<ActionTypes>,
  state$: StateObservable<IAppState>
) =>
  action$
    .ofType<IGetAffiliatesReportAction>(ActionKeys.GET_AFFILIATES_REPORT)
    .pipe(
      mergeMap((action: IGetAffiliatesReportAction) =>
        ajax
          .post(
            getAffiliatesReportEndpoint,
            { pin: action.pin },
            {
              Accept: API_VERSION,
              'Accept-Language': state$.value.locale,
              Authorization: `Bearer ${state$.value.token}`,
            }
          )
          .pipe(
            map((event) => {
              const response: IGetAffiliatesReportResponse = event.response;

              return getAffiliatesReportFulfilled(response);
            }),
            catchError((error: IAPIError): any => {
              if (error.status === 401) {
                return of(invalidateToken());
              }
              if (action.meta) {
                action.meta.reject(error);
              }
              return of(getStatusFail(error));
            })
          )
      )
    );
