import i18n from 'i18next';
import { en, fr } from './translations';

i18n.init({
  debug: false,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
  },
  keySeparator: '.',
  ns: [],
  react: {
    wait: true,
  },
  resources: {
    en: {
      translations: en,
    },
    fr: {
      translations: fr,
    },
  },
});

export { i18n };
