import { FormikProps, withFormik } from 'formik';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Yup from 'yup';
import { Form, Button } from 'semantic-ui-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { IAppState } from '../../reducers';
import { getErrorMessageFromReasonCode } from '../../util/getErrorMessageFromReasonCode';

import PinBubble from '../PinBubble';
import { reserve, ReasonCodes } from '../../actions/pins';

interface IAddressEntryScreenProps extends WithNamespaces {
  reserve: any;
}

interface IStateProps {
  reasonCode?: ReasonCodes;
  hasError: boolean;
  locale: string;
  normalizedPin?: string;
}

interface IFormValues {
  address: string;
}

const AddressEntryScreen = (
  props: FormikProps<IFormValues> & IAddressEntryScreenProps & IStateProps
) => {
  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    locale,
    normalizedPin,
    hasError,
    reasonCode,
    t,
  } = props;

  return (
    <div>
      <PinBubble
        valid={hasError}
        normalizedPin={normalizedPin}
        errorMessage={
          reasonCode && getErrorMessageFromReasonCode(t, locale, reasonCode, {})
        }
      />
      <Form size="large" onSubmit={handleSubmit} autoComplete="off">
        <Form.Field>
          <label htmlFor="address" style={{ display: 'block' }}>
            {t('labels.enterAddress')}
          </label>
          <input
            id="address"
            placeholder=""
            type="text"
            value={values.address}
            onChange={handleChange}
            onBlur={handleBlur}
            className={
              errors.address && touched.address
                ? 'text-input error'
                : 'text-input'
            }
          />
          {errors.address && touched.address && (
            <div className="input-feedback ui message">{errors.address}</div>
          )}
        </Form.Field>

        <Button size="large" color="blue" type="submit" loading={isSubmitting}>
          {t('buttons.validate')}
        </Button>
      </Form>
    </div>
  );
};

type IEnhancedFormProps = IAddressEntryScreenProps &
  IStateProps &
  RouteComponentProps<{}>;

const EnhancedForm = withFormik<IEnhancedFormProps, IFormValues>({
  displayName: 'AddressEntryScreen',
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await new Promise((resolve, reject) => {
        props.reserve(props.normalizedPin, values.address, {
          reject,
          resolve,
        });
      });

      setSubmitting(false);
    } catch (err) {
      setSubmitting(false);
      throw err;
    }
  },
  mapPropsToValues: () => ({ address: '' }),
  validationSchema: (props: IEnhancedFormProps) =>
    Yup.object().shape({
      address: Yup.string()
        .required(props.t('formValidation.postalCodeRequired'))
        // Took this RegEx from protal/src/constants.ts
        .matches(
          /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
          props.t('formValidation.postalCodeInvalid')
        ),
    }),
})(AddressEntryScreen);

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    hasError: !state.invalidAddressReasonCode,
    locale: state.locale,
    normalizedPin: state.normalizedPin,
    reasonCode: state.invalidAddressReasonCode,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      reserve,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withNamespaces()(EnhancedForm)));
