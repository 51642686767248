/* General Action Types and Action Creators */

export enum ActionKeys {
  NOOP = 'NOOP',
  APP_INIT = 'APP_INIT',
  RESET_APP = 'RESET_APP',
  RECEIVE_TOKEN = 'RECEIVE_TOKEN',
  INVALIDATE_TOKEN = 'INVALIDATE_TOKEN',
  SET_LOCALE = 'SET_LOCALE',
  SET_ACCESS = 'SET_ACCESS',
}

export type Access = {
  ispFlow: boolean;
  pinHistory: boolean;
  pinLookup: boolean;
  pinValidation: boolean;
  report: boolean;
};

/* Action Types */

export interface INoopAction {
  type: ActionKeys.NOOP;
}

export interface IAppInitAction {
  type: ActionKeys.APP_INIT;
}

export interface IResetAppAction {
  type: ActionKeys.RESET_APP;
}

export interface IReceiveToken {
  token: string;
  type: ActionKeys.RECEIVE_TOKEN;
}
export interface ISetAccess {
  access?: Access;
  type: ActionKeys.SET_ACCESS;
}

export interface IInvalidateToken {
  type: ActionKeys.INVALIDATE_TOKEN;
}

export interface ISetLocale {
  locale: string;
  type: ActionKeys.SET_LOCALE;
}

export type ActionTypes =
  | IAppInitAction
  | INoopAction
  | IResetAppAction
  | IReceiveToken
  | IInvalidateToken
  | ISetAccess
  | ISetLocale;

/* Action Creators */

export const appInit = (): IAppInitAction => ({
  type: ActionKeys.APP_INIT,
});

export const noop = (): INoopAction => ({
  type: ActionKeys.NOOP,
});

export const resetApp = (): IResetAppAction => ({
  type: ActionKeys.RESET_APP,
});

export const receiveToken = (token: string): IReceiveToken => ({
  token,
  type: ActionKeys.RECEIVE_TOKEN,
});

export const setAccess = (access?: Access): ISetAccess => ({
  access,
  type: ActionKeys.SET_ACCESS,
});

export const invalidateToken = (): IInvalidateToken => ({
  type: ActionKeys.INVALIDATE_TOKEN,
});

export const setLocale = (locale: string): ISetLocale => {
  const coercedLocale = /^fr/.test(locale) ? 'fr' : 'en';
  return {
    locale: coercedLocale,
    type: ActionKeys.SET_LOCALE,
  };
};
