import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ActionsObservable, StateObservable } from 'redux-observable';
import * as Sentry from '@sentry/browser';

import { IAppState } from '../reducers';
import {
  ActionKeys,
  ActionTypes,
  IAPIError,
  IAddStatusLogAction,
  IAddStatusLogResponse,
  addStatusLogFulfilled,
  addStatusLogFail,
  getStatusLogsFulfilled,
  getStatusLogsFail,
  IGetStatusLogsAction,
  IGetStatusLogsResponse,
} from '../actions/statusLogs';
import { invalidateToken } from '../actions/app';
import { API_VERSION } from '../api/version';

const addStatusLogEndpoint =
  process.env.REACT_APP_API_BASE_URL + '/pin/add-status-log';

export const addStatusLogEpic = (
  action$: ActionsObservable<ActionTypes>,
  state$: StateObservable<IAppState>
) =>
  action$.ofType<IAddStatusLogAction>(ActionKeys.ADD_STATUS_LOG).pipe(
    mergeMap((action) =>
      ajax
        .post(
          addStatusLogEndpoint,
          { pin: action.pin, message: action.message },
          { Accept: API_VERSION, Authorization: `Bearer ${state$.value.token}` }
        )
        .pipe(
          map((event) => {
            const response: IAddStatusLogResponse = event.response;
            if (action.meta) {
              action.meta.resolve();
            }
            return addStatusLogFulfilled(response);
          }),
          catchError((error: IAPIError): any => {
            Sentry.captureException(error);
            if (error.status === 401) {
              return of(invalidateToken());
            }
            if (action.meta) {
              action.meta.reject(error);
            }
            return of(addStatusLogFail(error));
          })
        )
    )
  );

const getStatusLogsEndpoint =
  process.env.REACT_APP_API_BASE_URL + '/pin/get-status-logs';

export const getStatusLogsEpic = (
  action$: ActionsObservable<ActionTypes>,
  state$: StateObservable<IAppState>
) =>
  action$.ofType<IGetStatusLogsAction>(ActionKeys.GET_STATUS_LOGS).pipe(
    mergeMap((action) =>
      ajax
        .post(
          getStatusLogsEndpoint,
          { pin: action.pin },
          { Accept: API_VERSION, Authorization: `Bearer ${state$.value.token}` }
        )
        .pipe(
          map((event) => {
            const response: IGetStatusLogsResponse = event.response;
            return getStatusLogsFulfilled(response);
          }),
          catchError((error: IAPIError): any => {
            Sentry.captureException(error);
            if (error.status === 401) {
              return of(invalidateToken());
            }
            return of(getStatusLogsFail(error));
          })
        )
    )
  );
