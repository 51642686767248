import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as Sentry from '@sentry/browser';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { createEpicMiddleware } from 'redux-observable';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { rootEpic } from './epics';

// Polyfills for IE11 support.
import 'core-js/features/array/find';
import 'core-js/features/array/find-index';
import 'core-js/features/array/from';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';
import 'core-js/features/object/assign';
import 'core-js/features/object/values';
import 'core-js/features/string/includes';
import 'core-js/es/symbol';
import 'core-js/es/promise';

import { appInit } from './actions/app';
import { App } from './components/App';
import { MultilingualErrorBoundary } from './components/MultilingualErrorBoundary';
import { rootReducer } from './reducers';

import './index.css';
import 'semantic-ui-theme/semantic/dist/semantic.min.css';
import { createSentryMiddleware } from './middleware/sentryForRedux';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN || '',
  environment: process.env.REACT_APP_ENVIRONMENT_NAME || 'development',
});

const history = createBrowserHistory();
const epicMiddleware = createEpicMiddleware();
const composeEnhancers = composeWithDevTools({});

const store = createStore(
  connectRouter(history)(rootReducer),
  composeEnhancers(
    applyMiddleware(
      routerMiddleware(history),
      epicMiddleware,
      createSentryMiddleware(Sentry)
    )
  )
);

epicMiddleware.run(rootEpic);

store.dispatch(appInit());

ReactDOM.render(
  <MultilingualErrorBoundary>
    <Provider store={store}>
      <App history={history} />
    </Provider>
  </MultilingualErrorBoundary>,
  document.getElementById('root') as HTMLElement
);
