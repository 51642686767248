import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid } from 'semantic-ui-react';
import { Route } from 'react-router-dom';
import { push } from 'connected-react-router';
import { Trans, withNamespaces, WithNamespaces } from 'react-i18next';
import { Access, resetApp, setLocale } from '../../actions/app';
import { IAppState } from '../../reducers';
import NavBar from '../NavBar';
import AddressEntryScreen from './AddressEntryScreen';
import PinEntryScreen from './PinEntryScreen';
import PinErrorScreen from './PinErrorScreen';
import PinStatusLockedScreen from './PinStatusLockedScreen';
import PinStatusReservedScreen from './PinStatusReservedScreen';
import PinStatusUsedScreen from './PinStatusUsedScreen';
import PinStatusReleasedScreen from './PinStatusReleasedScreen';
import PinTool from './../PinTool';
import PinLookup from './../PinLookup';
import AffiliatesTool from '../AffiliatesTool';
import { CSVUpload } from './CSVUpload';
import { CSVUploadConfirm } from './CSVUploadConfirm';
import { CSVUploadResults } from './CSVUploadResults';

interface IHomeProps {
  resetApp: () => any;
  setLocale: (locale: string) => any;
  push: typeof push;
  access?: Access;
}

interface IStateProps {
  locale: string;
  pathname?: string;
}

const Wizard = (props: IHomeProps & IStateProps & WithNamespaces) => {
  const { access } = props;
  const isSuperUser =
    access !== undefined && Object.values(access).every((value) => value);

  // Watch out using this Trans component. If the a tag isn't on the first line it fails :( !!!
  const disclaimer = (
    <Trans i18nKey="general.disclaimer">
      Fit <a href={props.t('links.connectingFamiliesAdmin')}>one</a> line.
    </Trans>
  );

  if (access === undefined) {
    return null;
  }

  return (
    <div>
      <header>
        <NavBar
          showCSVButton={!isSuperUser}
          onCSVClick={() => props.push('/csv-upload')}
          onNewPinClick={() => props.push('/')}
          onPinToolClick={() =>
            access.ispFlow ? props.push('/pin-tool') : props.push('/')
          }
          onFindApplicatopnsClick={() => props.push('/find-applications')}
          onAffiliatesClick={() =>
            access.pinValidation
              ? props.push('/affiliates-tool')
              : props.push('/')
          }
          locale={props.locale}
          setLocale={props.setLocale}
          access={access}
        />
      </header>

      <Grid centered={true} className="wizard" container={true}>
        <div className="column">
          {process.env.REACT_APP_ENVIRONMENT_NAME === 'stage' && (
            <Route path="/">
              <div className="ui message error">{disclaimer}</div>
            </Route>
          )}
          <Route exact={true} path="/">
            {access.ispFlow ? (
              <PinEntryScreen showCSVUpload={isSuperUser} />
            ) : access.pinHistory ? (
              <PinTool />
            ) : (
              <AffiliatesTool />
            )}
          </Route>
          {access.ispFlow && (
            <>
              <Route
                exact={true}
                path="/pin-error"
                component={PinErrorScreen}
              />
              <Route
                exact={true}
                path="/pin-locked"
                component={PinStatusLockedScreen}
              />
              <Route
                exact={true}
                path="/pin-reserved"
                component={PinStatusReservedScreen}
              />
              <Route
                exact={true}
                path="/pin-used"
                component={PinStatusUsedScreen}
              />
              <Route
                exact={true}
                path="/pin-released"
                component={PinStatusReleasedScreen}
              />
              <Route
                exact={true}
                path="/pin-address-entry"
                component={AddressEntryScreen}
              />
              <Route exact={true} path="/csv-upload" component={CSVUpload} />
              <Route
                exact={true}
                path="/csv-confirm"
                component={CSVUploadConfirm}
              />
              <Route
                exact={true}
                path="/csv-results"
                component={CSVUploadResults}
              />
            </>
          )}
          {access.pinHistory && (
            <Route exact={true} path="/pin-tool" component={PinTool} />
          )}
          {access.pinLookup && (
            <Route exact={true} path="/find-applications">
              <PinLookup ownUrlForPinTool={isSuperUser} />
            </Route>
          )}
          {access.pinValidation && (
            <Route
              exact={true}
              path="/affiliates-tool"
              component={AffiliatesTool}
            />
          )}
        </div>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    locale: state.locale,
    pathname: state.router && state.router.location.pathname,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      push,
      resetApp,
      setLocale,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(Wizard));
